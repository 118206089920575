@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap");
/* line 1, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/libaray/_object.scss */
.red {
  color: red !important;
}

/* line 2, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/libaray/_object.scss */
.border-red {
  border: solid 2px red !important;
}

/* line 1, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/_custom-tailwind.scss */
.help-price-page {
  padding: 20px;
}

/* line 3, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/_custom-tailwind.scss */
.help-price-page .hp-image {
  width: 200px;
}

/* line 6, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/_custom-tailwind.scss */
.help-price-page .hp-text {
  width: calc(100% - 210px);
  margin: 24px;
}

/* line 10, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/_custom-tailwind.scss */
.help-price-page .btn-see-more {
  margin-left: 8px;
}

/* line 14, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/_custom-tailwind.scss */
.accordion-collapse.collapse.show {
  visibility: visible !important;
}

/* line 19, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/_custom-tailwind.scss */
.order-table td, .order-table th {
  border: none;
}

/* line 23, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/_custom-tailwind.scss */
.order-table .border-botton td, .order-table .border-botton th {
  border-bottom: solid 1px #cccccc;
}

/* line 29, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/_custom-tailwind.scss */
.DateRangePicker {
  left: calc(50% - 200px);
}

/* line 32, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/_custom-tailwind.scss */
.offset-layer {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  cursor: pointer;
}

/* line 42, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/_custom-tailwind.scss */
.form-select {
  font-size: 14px !important;
}

/* line 46, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/_custom-tailwind.scss */
.custom-borser-buttom {
  border-bottom: solid 1px #cccccc;
}

/* line 49, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/_custom-tailwind.scss */
.display-flex {
  align-items: center;
}

/* line 50, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/_custom-tailwind.scss */
.display-flex img {
  margin-right: 8px;
}

/* line 1, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/base/_body.scss */
.btn-submit {
  background: black;
  color: white;
}

/* line 4, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/base/_body.scss */
.btn-submit:hover {
  background: #008951;
  color: white;
}

/* line 6, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/base/_base.scss */
.ck-powered-by {
  display: none;
}

/* line 9, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/base/_base.scss */
hr {
  margin: 1rem 0 !important;
  color: inherit;
  opacity: .25;
}

/* in reser aresa */
/* line 2, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/base/_reset.scss */
* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  font-family: Inter;
}

/* line 10, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/base/_reset.scss */
body {
  font-family: Arial, Helvetica, sans-serif;
  color: #000000;
  border-top: solid 1px #ffffff;
  font-size: 14px;
  margin: 0;
  padding: 0;
}

/* line 19, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/base/_reset.scss */
h1 {
  font-size: 48px;
  font-family: "Poppins", sans-serif;
}

/* line 24, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/base/_reset.scss */
h2 {
  font-size: 2em;
}

/* line 28, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/base/_reset.scss */
h3 {
  font-size: 1.8em;
}

/* line 32, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/base/_reset.scss */
h4 {
  font-size: 1.6em;
}

/* line 36, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/base/_reset.scss */
h5 {
  font-size: 1.4em;
}

/* line 40, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/base/_reset.scss */
p {
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  font-family: "Lato", sans-serif;
}

/* line 47, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/base/_reset.scss */
a {
  text-decoration: none;
  color: inherit;
}

/* line 52, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/base/_reset.scss */
img {
  height: auto;
  border: 0;
  display: inline-block;
}

/* line 59, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/base/_reset.scss */
#root {
  background: #f6f6f6;
}

/* line 63, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/base/_reset.scss */
.alert {
  width: 100%;
}

/* line 67, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/base/_reset.scss */
ol.list-style-auto {
  list-style: auto;
}

/* line 70, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/base/_reset.scss */
.hide {
  display: none;
}

/* line 73, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/base/_reset.scss */
.show {
  display: block;
}

/* in reser aresa */
/* line 1, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/layout/_logo.scss */
.logo {
  padding: 0 0 0 60px;
  align-self: center;
  color: #000;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  height: 60px;
  align-items: center;
  cursor: pointer;
}

/* line 23, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/layout/_logo.scss */
.left-side-bar .logo {
  display: flex;
  height: 40px;
  align-items: center;
  cursor: pointer;
  width: calc(100% - 25px);
  padding: 0;
  position: relative;
  margin: 12px;
}

/* line 33, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/layout/_logo.scss */
.left-side-bar .logo img {
  max-height: 40px !important;
  max-width: 40px !important;
  margin: 0;
}

/* line 38, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/layout/_logo.scss */
.left-side-bar .logo .icon {
  position: absolute;
  right: 0;
  color: #666666;
  width: 40px;
  height: 40px;
  border: dashed 1px white;
}

/* line 1, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/layout/_menu-icon.scss */
.menu-icon {
  width: 60px;
  height: 60px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
}

/* line 12, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/layout/_menu-icon.scss */
.menu-icon .centered {
  position: absolute;
  top: 50%;
  right: 50%;
  -webkit-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
  display: block;
  width: 30px;
  height: 30px;
  cursor: pointer;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  padding: 0;
}

/* line 26, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/layout/_menu-icon.scss */
.menu-icon .centered .hr_bar {
  display: block;
  position: absolute;
  height: 2px;
  background-color: #262633;
  left: 0;
  right: 0;
  -webkit-transform: rotate(0deg) scaleX(1) translateY(0);
  transform: rotate(0deg) scaleX(1) translateY(0);
  -webkit-transition: .3s linear;
  transition: .3s linear;
  -webkit-transform-origin: left center;
  transform-origin: left center;
  top: 7.5px;
  border-radius: 1px;
}

/* line 41, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/layout/_menu-icon.scss */
.menu-icon .centered .hr_bar:first-child {
  top: 0;
  -webkit-transform-origin: left top;
  transform-origin: left top;
}

/* line 46, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/layout/_menu-icon.scss */
.menu-icon .centered .hr_bar:last-child {
  top: 15px;
  -webkit-transform-origin: left bottom;
  transform-origin: left bottom;
}

/* line 55, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/layout/_menu-icon.scss */
.menu-icon.selected .hr_bar:first-child {
  transform: translateX(6px) rotate(45deg) scaleX(0.82465);
}

/* line 58, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/layout/_menu-icon.scss */
.menu-icon.selected .hr_bar:nth-child(2) {
  transform: translateX(-50%);
  opacity: 0;
}

/* line 62, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/layout/_menu-icon.scss */
.menu-icon.selected .hr_bar:last-child {
  transform: translateX(6px) rotate(-45deg) scaleX(0.82465);
}

/* line 66, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/layout/_menu-icon.scss */
.menu-icon.selected .centered {
  width: 26px;
  height: 16px;
}

/* line 1, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/layout/_footer.scss */
footer {
  width: 100%;
  padding: 15px;
  text-align: center;
  border-top: solid 1px #cccccc;
  position: absolute;
  bottom: 0;
  right: 0;
}

/* line 12, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/layout/_footer.scss */
footer.selected {
  width: calc(100% - 280px + 20px);
}

/* in layout area*/
/* line 8, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/layout/_layout.scss */
.container {
  max-width: 100%;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

/* line 20, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/layout/_layout.scss */
.wrapper {
  margin: 70px 20px;
  width: 100%;
  min-height: calc(100vh - 145px);
}

/* line 25, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/layout/_layout.scss */
.wrapper.selected {
  width: calc(100% - 300px);
  margin: 70px 20px 70px 280px;
}

@media screen and (min-width: 2401px) {
  /* line 25, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/layout/_layout.scss */
  .wrapper.selected {
    max-width: 1900px;
    margin: 0 auto;
  }
}

/* line 34, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/layout/_layout.scss */
.left-side-bar {
  background: #fefefe;
  position: fixed;
  width: 260px;
  margin-top: 60px;
  overflow-y: scroll;
  height: calc(100vh - 60px);
  /*  ************************************   .left-side-bar *************************/
  /*  ************************************   .left-side-bar *************************/
}

/* line 3, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/layout/_left-side-bar.scss */
.left-side-bar ul {
  list-style: none;
  padding: 1px 0 0 0;
}

/* line 10, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/layout/_left-side-bar.scss */
.left-side-bar ul li {
  margin: 2px 15px;
}

/* line 13, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/layout/_left-side-bar.scss */
.left-side-bar ul li a {
  padding: 12px 8px;
  display: flex;
  border-radius: 8px;
}

/* line 17, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/layout/_left-side-bar.scss */
.left-side-bar ul li a i:before {
  content: '+';
  font-weight: bold;
  font-style: normal;
  padding-right: 5px;
}

/* line 25, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/layout/_left-side-bar.scss */
.left-side-bar ul li a.active {
  background: #F7F7F7;
}

/* line 31, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/layout/_left-side-bar.scss */
.left-side-bar ul li a.active i:before {
  content: '-';
  font-weight: bold;
  font-style: normal;
  padding-right: 5px;
}

/* line 40, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/layout/_left-side-bar.scss */
.left-side-bar ul li a:hover {
  color: inherit;
}

/* line 61, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/layout/_left-side-bar.scss */
.left-side-bar ul ul {
  display: none;
}

/* line 71, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/layout/_left-side-bar.scss */
.left-side-bar ul ul li {
  margin: 2px 0 !important;
}

/* line 75, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/layout/_left-side-bar.scss */
.left-side-bar ul ul a {
  display: flex;
  align-items: center;
  color: #767676;
  padding: 8px 4px;
}

/* line 80, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/layout/_left-side-bar.scss */
.left-side-bar ul ul a::before {
  content: '';
  width: 5px;
  height: 5px;
  border-radius: 50%;
  display: block;
  background: #767676;
  margin: 0 6px;
}

/* line 89, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/layout/_left-side-bar.scss */
.left-side-bar ul ul a:hover {
  background: white;
  color: inherit;
}

/* line 93, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/layout/_left-side-bar.scss */
.left-side-bar ul ul a.active {
  background: white;
  color: #047857;
}

/* line 103, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/layout/_left-side-bar.scss */
.left-side-bar .active ~ ul {
  display: block;
  padding: 8px;
  margin-top: 8px;
  background: #F7F7F7;
}

/* line 116, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/layout/_left-side-bar.scss */
.left-side-bar img {
  max-width: 16px;
  margin-right: 12px;
}

@keyframes menu-sub {
  0% {
    height: 1px;
  }
  70% {
    height: 100px;
  }
  100% {
    height: auto;
  }
}

@keyframes menu-sub-off {
  0% {
    height: 1px;
  }
  100% {
    height: auto;
  }
}

/*  in header area */
/* line 2, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/layout/_header.scss */
header {
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 60px;
  box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.15);
  background: #FFFFFF;
  left: 0;
  top: 0;
  border-top: dashed 1px #008951;
}

/* line 15, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/layout/_header.scss */
.header-wrapper {
  position: relative;
}

/* line 18, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/layout/_header.scss */
.header-section {
  padding: 12px 8px;
  position: relative;
}

/* line 23, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/layout/_header.scss */
.header-section .page-title {
  color: #333;
  font-family: Inter;
  font-weight: 600;
  font-size: 20px;
}

/* line 30, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/layout/_header.scss */
.profile {
  position: absolute;
  right: 15px;
  top: 1px;
  display: flex;
  align-items: center;
  margin: 7px 0;
}

/* line 38, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/layout/_header.scss */
.profile .user {
  color: #333;
  display: flex;
  align-items: center;
  cursor: pointer;
}

/* line 44, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/layout/_header.scss */
.profile .name {
  min-width: 120px;
  display: inline-block;
  font-weight: 500;
  font-size: 16px;
}

/* line 50, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/layout/_header.scss */
.profile .drop-down {
  width: 270px;
  align-items: center;
  flex-shrink: 0;
  background: #FFF;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 100%;
  right: 0;
  border-radius: 10px;
}

/* line 60, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/layout/_header.scss */
.profile .drop-down .item {
  border-bottom: solid 1px #E9E9E9;
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  padding: 8px;
}

/* line 68, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/layout/_header.scss */
.profile .drop-down .item:last-child {
  border: none;
}

/* line 71, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/layout/_header.scss */
.profile .drop-down .item .icon {
  width: 24px;
  height: 24px;
  margin: 6px 12px;
  border-radius: 50%;
  font-size: 24px;
}

/* line 79, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/layout/_header.scss */
.profile .drop-down .item .text {
  padding: 4px;
}

/* line 86, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/layout/_header.scss */
.user-icon {
  margin: 0 15px 0 0;
  border: solid 1px #cccccc;
  max-width: 42px;
  background: #fff;
  border-radius: 50%;
}

/* line 93, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/layout/_header.scss */
.down-icon {
  margin: 0 8px 0 0;
}

/*  in header area */
/* line 101, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/layout/_header.scss */
.breadcrumb {
  margin: 0;
  border-radius: 8px;
  font-size: 14px;
  text-transform: capitalize !important;
  color: black;
}

/* line 110, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/layout/_header.scss */
.breadcrumb a {
  color: black;
}

/* line 113, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/layout/_header.scss */
.breadcrumb:empty {
  display: none;
}

/* line 55, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/layout/_layout.scss */
::-webkit-scrollbar {
  height: 10px;
}

/* Track */
/* line 60, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/layout/_layout.scss */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
/* line 65, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/layout/_layout.scss */
::-webkit-scrollbar-thumb {
  background: #e7e8ec;
}

/* Handle on hover */
/* line 71, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/layout/_layout.scss */
::-webkit-scrollbar-thumb:hover {
  background: #ddd;
}

/* line 75, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/layout/_layout.scss */
::-webkit-scrollbar {
  width: 10px;
}

/* line 1, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/_under-constraction.scss */
.under-construction {
  height: calc(100vh - 220px);
  position: relative;
  /* Add a white text color to all elements inside the .bgimg container */
  color: white;
  /* Add a font */
  font-family: "Courier New", Courier, monospace;
  /* Set the font-size to 25 pixels */
  font-size: 25px;
  background: #008951;
}

/* line 19, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/_under-constraction.scss */
.under-construction .topleft {
  position: absolute;
  top: 0;
  left: 16px;
}

/* line 26, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/_under-constraction.scss */
.under-construction .bottomleft {
  position: absolute;
  bottom: 0;
  left: 16px;
}

/* line 33, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/_under-constraction.scss */
.under-construction .middle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

/* line 42, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/_under-constraction.scss */
.under-construction hr {
  margin: auto;
  width: 100%;
}

/* line 1, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/_not-found.scss */
.not-found {
  height: calc(100vh - 220px);
  position: relative;
  /* Add a white text color to all elements inside the .bgimg container */
  color: #000000;
  /* Add a font */
  font-family: "Courier New", Courier, monospace;
  /* Set the font-size to 25 pixels */
  font-size: 25px;
}

/* line 19, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/_not-found.scss */
.not-found .topleft {
  position: absolute;
  top: 0;
  left: 16px;
}

/* line 26, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/_not-found.scss */
.not-found .bottomleft {
  position: absolute;
  bottom: 0;
  left: 16px;
}

/* line 33, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/_not-found.scss */
.not-found .middle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

/* line 42, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/_not-found.scss */
.not-found hr {
  margin: auto;
  width: 100%;
}

/* line 3, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_svg-background.scss */
.copy {
  background: url("../images/copy.svg") no-repeat center;
}

/* line 6, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_svg-background.scss */
.down {
  background: url("../images/rank-down.svg") no-repeat center;
}

/* line 9, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_svg-background.scss */
.up {
  background: url("../images/rank-up.svg") no-repeat center;
}

/* line 1, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_btn.scss */
.btn-default {
  border-radius: 10px;
  border: 1px solid var(--App-Grey-stroke, #E9E9E9) !important;
  background: white;
}

/* line 7, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_btn.scss */
.small-btn [class^=btn-] {
  background: #E9E9E9;
  margin-right: 5px;
  padding: 12px 8px;
  border-radius: 5px;
  cursor: pointer;
}

/* line 13, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_btn.scss */
.small-btn [class^=btn-]:last-child {
  margin: 0;
}

/* line 16, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_btn.scss */
.small-btn [class^=btn-]:hover {
  background: #008951;
  color: white;
}

/* line 22, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_btn.scss */
.vertical-action-point {
  background: #E9E9E9;
  font-size: 20px;
  text-align: center;
  padding: 8px 16px;
  border-radius: 10px;
  box-shadow: 0 0 10px #ececec;
}

/* line 1, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_item-configuration.scss */
.drag-title {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

/* line 11, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_item-configuration.scss */
.item-configuration .checkbox-field input, .item-configuration .radio-field input {
  width: 23px;
  height: 23px;
  margin: 2px 8px;
}

/* line 17, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_item-configuration.scss */
.item-configuration legend.have-measurement-type {
  display: flex;
  width: 100%;
}

/* line 20, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_item-configuration.scss */
.item-configuration legend.have-measurement-type .form-control {
  width: calc(100% - 160px);
  margin-right: 10px;
}

/* line 24, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_item-configuration.scss */
.item-configuration legend.have-measurement-type .form-select {
  width: 150px;
}

/* in block component */
/* line 4, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_block.scss */
.content-block .top-btn-area, .content-block .wrap-content {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
  padding: 10px;
  background: white;
  border-radius: 12px;
  width: 100%;
}

/* line 13, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_block.scss */
.content-block .wrap-content {
  padding: 16px 32px;
}

/* line 18, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_block.scss */
.border {
  width: 100%;
}

/* line 22, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_block.scss */
.section-single-column {
  background: white;
  padding: 32px;
}

/* line 27, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_block.scss */
.section-two-column {
  display: flex;
  flex-wrap: wrap;
}

/* line 32, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_block.scss */
.left-block {
  width: calc(100% - (307px + 24px));
  background: white;
  padding: 16px 32px;
  border-radius: 12px;
  position: relative;
}

/* line 39, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_block.scss */
.left-block.w100-200 {
  width: calc(100% - 300px - 24px);
}

/* line 45, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_block.scss */
.right-block {
  width: 307px;
  border-radius: 12px;
  margin-left: 24px;
}

/* line 52, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_block.scss */
.right-block.w100-200 {
  width: 300px;
}

/* line 57, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_block.scss */
.new-configuration-btn {
  color: white;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: black;
  border-radius: 12px;
  padding: 10px 16px 10px 16px;
  align-items: center;
  margin: 10px;
  position: relative;
  display: flex;
}

/* line 73, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_block.scss */
.new-configuration-btn:hover {
  color: #dddddd;
}

/* line 77, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_block.scss */
.new-configuration-btn:before {
  content: '';
  border-radius: 50%;
  background: white;
  height: 36px;
  width: 36px;
  display: inline-block;
  color: black;
  font-size: 30px;
  line-height: 15px;
  margin-right: 6px;
  padding: 9px 8px;
}

/* line 90, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_block.scss */
.new-configuration-btn.add-new {
  cursor: pointer;
}

/* line 92, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_block.scss */
.new-configuration-btn.add-new:before {
  content: '+';
}

/* line 98, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_block.scss */
.new-configuration-btn.edit:before {
  content: '.';
  color: #cccccc;
  font-size: 1px;
  line-height: 30px;
  background: white url("../images/edit.svg") no-repeat center;
}

/* in block component */
/* in table component */
/* line 3, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_table.scss */
table {
  width: 100%;
}

/* line 7, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_table.scss */
table td,
table th {
  border: 1px solid var(--stroke-color-category-help-abode, #E9E9E9);
}

/* line 12, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_table.scss */
table td {
  padding: 12px 10px;
}

/* line 15, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_table.scss */
table th {
  font-weight: bold;
  padding: 15px;
  text-align: left;
}

/* in table component */
/* line 25, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_table.scss */
.service-attributes-table th {
  background: #f6f6f6;
  border: none;
  font-weight: normal;
  width: 100px;
}

/* line 31, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_table.scss */
.service-attributes-table th.options {
  width: auto;
}

/* line 33, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_table.scss */
.service-attributes-table th.estimated-time {
  width: 150px;
}

/* line 35, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_table.scss */
.service-attributes-table th.bold {
  font-weight: bold;
}

/* line 37, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_table.scss */
.service-attributes-table td {
  border-bottom: solid 2px #f6f6f6;
}

/* line 40, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_table.scss */
.service-attributes-table .options-group {
  border: solid 2px inherit;
}

/* line 44, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_table.scss */
.rdt_TableCell {
  padding: 0 8px !important;
}

/* line 46, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_table.scss */
.rdt_TableCell:last-child {
  justify-content: right;
}

/* line 49, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_table.scss */
.rdt_TableCell .btn {
  width: 100%;
}

/* line 53, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_table.scss */
.kBksXg:last-child {
  justify-content: space-evenly;
}

/* line 7, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_service-modal.scss */
.modal-dialog {
  width: 700px;
  --bs-modal-width: 700px;
}

/* line 1, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-element.scss */
.form-switch {
  cursor: pointer;
  display: flex;
  align-items: center;
}

/* line 6, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-element.scss */
.form-switch .form-check-input {
  height: 1em;
  width: 2em;
  margin-top: 6px;
  box-shadow: 0 0 0 0.25rem #d5e7e0;
}

/* line 12, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-element.scss */
.form-switch .form-check-input:checked {
  background-color: #21b26f;
  border-color: #198754;
}

/* line 21, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-element.scss */
.form-switch .form-check-label {
  line-height: normal;
  padding-left: 15px;
  cursor: pointer;
}

/* line 28, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-element.scss */
.update-btn {
  background-color: #008951;
  color: white;
}

/* line 32, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-element.scss */
.update-btn:hover {
  background-color: #00b269;
  color: #fdfdfd;
}

/* line 36, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-element.scss */
.update-btn .fa {
  font-size: 12px;
  margin-right: 5px;
}

/* line 43, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-element.scss */
.form-data .field-row {
  margin-bottom: 20px;
  width: 100%;
  display: block;
  font-size: 16px;
}

/* line 49, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-element.scss */
.form-data .field-row label.radio-btn {
  width: 100%;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
}

/* line 56, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-element.scss */
.form-data .field-row label.radio-btn input {
  display: none;
}

/* line 60, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-element.scss */
.form-data .field-row label.radio-btn span {
  border: solid 2px black;
  background: white;
  position: relative;
  display: inline-block;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin-right: 8px;
}

/* line 69, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-element.scss */
.form-data .field-row label.radio-btn span:after {
  content: '';
  position: absolute;
  background: white;
  width: 70%;
  height: 70%;
  border-radius: 50%;
  top: 4px;
  left: 4px;
}

/* line 81, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-element.scss */
.form-data .field-row label.radio-btn input:checked ~ span {
  border: solid 2px #00A000;
}

/* line 83, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-element.scss */
.form-data .field-row label.radio-btn input:checked ~ span:after {
  background: #00A000;
}

/* line 89, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-element.scss */
.form-data .field-row .label {
  font-weight: 500;
  color: black;
  font-style: normal;
  line-height: normal;
  margin-bottom: 12px;
  display: block;
}

/* line 100, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-element.scss */
.form-data .field-row .editorData {
  display: none;
}

/* line 104, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-element.scss */
.form-data label[checked] {
  background: #00A000;
}

/* line 121, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-element.scss */
.right-block .category {
  position: relative;
  padding: 24px;
  margin-bottom: 36px;
  border-radius: 12px;
  background: var(--White, #FFF);
  text-align: center;
}

/* line 132, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-element.scss */
.right-block .category:last-child {
  margin: 0;
}

/* line 135, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-element.scss */
.right-block .category label {
  cursor: pointer;
}

/* line 139, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-element.scss */
.right-block .category .title, .right-block .category .info {
  display: block;
  text-align: left;
}

/* line 143, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-element.scss */
.right-block .category p {
  color: var(--Black, #000);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

/* line 151, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-element.scss */
.right-block .category .info {
  color: var(--input, #535151);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 4px 0;
}

/* line 165, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-element.scss */
.right-block .category #drop-area, .right-block .category .drop-image {
  position: absolute;
  right: -5px;
  top: -5px;
  display: flex;
  width: 36px;
  height: 36px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: white;
  color: gray;
  border-radius: 100px;
  background: #FFF;
  box-shadow: 0px 4px 16px 0px rgba(17, 17, 26, 0.05), 0px 8px 32px 0px rgba(17, 17, 26, 0.05);
}

/* line 184, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-element.scss */
.right-block .category #drop-area:hover, .right-block .category .drop-image:hover {
  background: #047857;
  color: white;
}

/* line 189, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-element.scss */
.right-block .category .img-view {
  margin-top: 20px;
  position: relative;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 12px;
  border: 1px solid #f6f6f6;
  padding: 2px;
  width: calc(100% - 2px);
  height: 250px;
  border-radius: 12px;
  border: 5px solid var(--White, #FFF);
  box-shadow: 0px 4px 16px 0px rgba(17, 17, 26, 0.05), 0px 8px 32px 0px rgba(17, 17, 26, 0.05);
  overflow: hidden;
}

/* line 205, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-element.scss */
.right-block .category .img-view img {
  width: 100%;
}

/* line 210, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-element.scss */
.right-block .category .img-view:hover #drop-area, .right-block .category .img-view:hover .drop-image {
  display: inline-block;
}

/* line 216, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-element.scss */
.right-block .category .canvas {
  width: 100%;
  max-height: 200px;
  overflow: hidden;
  margin-bottom: 8px;
}

/* line 222, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-element.scss */
.right-block .category .canvas img {
  width: 100%;
  max-width: 200px;
  height: auto;
}

/* line 232, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-element.scss */
.check-box label > div {
  display: inline;
}

/* line 236, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-element.scss */
.check-box label .label-name {
  padding-left: 16px;
}

/* line 241, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-element.scss */
.save-option {
  display: flex;
  gap: 24px;
  margin: 36px 0 30px 0;
}

/* line 245, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-element.scss */
.save-option button {
  cursor: pointer;
  display: inline-flex;
  padding: 12px 15px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  background: var(--btn-secondary, #e9e9e9);
  color: var(--Black, #000);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

/* line 260, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-element.scss */
.save-option button .draft-icon img {
  width: 20px;
  height: 20px;
}

/* line 265, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-element.scss */
.save-option .save-btn {
  background: var(--Black, #000);
  color: var(--Left_bar, #fff);
  padding: 12px 30px;
}

/* line 270, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-element.scss */
.save-option .reset-btn {
  border: solid 1px;
  padding: 11px 15px;
}

/* line 278, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-element.scss */
.subitem-manage .form-group {
  position: relative;
}

/* line 281, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-element.scss */
.subitem-manage .remove-item {
  position: absolute;
  right: 0;
  top: calc(50% - 0px);
  padding: 6px;
  cursor: pointer;
  border: solid 1px #dedede;
  background: #ccc;
  border-radius: 4px;
  margin: 3px 0;
}

/* line 293, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-element.scss */
.subitem-manage label {
  width: 100%;
  padding: 8px 8px 8px 0;
  font-weight: bold;
}

/* line 297, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-element.scss */
.subitem-manage label .span {
  padding: 4px;
  font-weight: normal;
}

/* line 305, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-element.scss */
.subitem-manage .radio-field,
.subitem-manage .checked-field {
  display: flex;
  margin: 4px 0;
  align-items: center;
}

/* line 310, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-element.scss */
.subitem-manage .radio-field input,
.subitem-manage .checked-field input {
  width: 23px;
  height: 23px;
  margin: 2px 8px;
}

/* line 315, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-element.scss */
.subitem-manage .radio-field label, .subitem-manage .radio-field input, .subitem-manage .radio-field .remove-option,
.subitem-manage .checked-field label,
.subitem-manage .checked-field input,
.subitem-manage .checked-field .remove-option {
  cursor: pointer;
}

/* line 318, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-element.scss */
.subitem-manage .radio-field .remove-option,
.subitem-manage .checked-field .remove-option {
  padding: 0 6px;
}

/* line 320, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-element.scss */
.subitem-manage .radio-field .remove-option:hover,
.subitem-manage .checked-field .remove-option:hover {
  color: #FF0000;
}

/* line 327, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-element.scss */
.draft-btn:before {
  content: '';
  background: url(../images/Drafts.svg) no-repeat;
  width: 20px;
  height: 20px;
  display: inline-block;
}

/* line 336, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-element.scss */
.edit-delete-btn {
  padding: 6px;
  cursor: pointer;
  margin: 5px;
  width: 30px;
  height: 30px;
  display: inline-block;
  border-radius: 50%;
  color: #AAA6A6;
  border: solid 1px #AAA6A6;
  position: relative;
}

/* line 352, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-element.scss */
.edit-delete-btn.remove {
  background: url("../images/Delete.svg") no-repeat center;
}

/* line 355, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-element.scss */
.edit-delete-btn.edit::before {
  content: "\f040";
  font: normal normal normal 14px / 1 FontAwesome;
  position: absolute;
  top: 7px;
}

/* line 364, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-element.scss */
.edit-delete-btn.add {
  background: url("../images/plus.svg") no-repeat center;
}

/* line 367, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-element.scss */
.edit-delete-btn.active {
  border: solid 1px #008951;
  background: #008951;
  color: white;
}

/* line 373, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-element.scss */
.edit-delete-btn:hover {
  border: solid 1px #008951;
}

/* line 378, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-element.scss */
.subitem-manage .edit-delete-btn {
  position: absolute;
  right: 6px;
  top: 10px;
  z-index: 99;
}

/* line 387, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-element.scss */
.pad-10 {
  padding: 10px;
}

/* line 390, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-element.scss */
.pricing-by label {
  width: 100px;
}

/* line 393, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-element.scss */
.is-price {
  display: flex;
  flex-wrap: wrap;
  border-top: dashed 2px #cccccc;
  margin: 20px 0 0 0;
  padding: 10px 0 0 0;
}

/* line 399, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-element.scss */
.is-price .title {
  width: 100%;
  margin-bottom: 12px;
  font-weight: bold;
}

/* line 405, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-element.scss */
.is-price input {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

/* line 410, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-element.scss */
.is-price label {
  padding: 0 8px !important;
  margin-right: 15px;
  width: auto;
  cursor: pointer;
}

/* line 422, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-element.scss */
.remove-option {
  cursor: pointer;
}

/* line 426, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-element.scss */
.checkbox-field, .radio-field {
  align-items: center;
  display: flex;
}

/* line 5, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_service-faruk.scss */
.dragged-form-item h1 {
  display: flex;
  padding: 12px 36px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: var(--offer, #D5E7E0);
  margin-bottom: 36px;
  color: var(--text_color, #000);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

/* line 21, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_service-faruk.scss */
.dragged-form-item h3 {
  color: #000;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 32px;
}

/* line 31, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_service-faruk.scss */
.dragged-form-item .form-item {
  border-radius: 10px;
  border: 1px solid var(--stroke-color-category-help-abode, #E9E9E9);
  background: var(--White, #FFF);
  padding: 16px 20px !important;
  margin: 24px 0;
}

/* line 40, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_service-faruk.scss */
.dragged-form-item .form-item .input-area .add-new-level {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  position: relative;
}

/* line 47, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_service-faruk.scss */
.dragged-form-item .form-item .input-area .add-new-level .add-new-plan {
  display: flex;
  padding: 10px 20px 10px 12px;
  align-items: center;
  border-radius: 12px;
  background: var(--btn-secondary, #E9E9E9);
  color: var(--Black, #000);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
}

/* line 61, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_service-faruk.scss */
.dragged-form-item .form-item .input-area .add-new-level .add-new-plan:hover {
  background: var(--btn-secondary, #C5C5C5);
}

/* line 65, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_service-faruk.scss */
.dragged-form-item .form-item .input-area .add-new-level .add-new-plan i {
  margin-right: 8px;
  border-radius: 100px;
}

/* line 77, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_service-faruk.scss */
.dragged-form-item .form-item .input-area .level .desc {
  color: var(--Black, #000);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

/* line 85, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_service-faruk.scss */
.dragged-form-item .form-item .input-area .level .desc span {
  color: red;
}

/* line 90, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_service-faruk.scss */
.dragged-form-item .form-item .input-area .level .input-area {
  margin-top: 10px;
}

/* line 93, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_service-faruk.scss */
.dragged-form-item .form-item .input-area .level .input-area textarea {
  width: 100%;
  height: 99px;
  border-radius: 6px;
  border: 1px solid var(--stroke, #E9E9E9);
  background: #FFF;
  padding: 12px 16px;
  color: rgba(0, 0, 0, 0.6);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* line 111, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_service-faruk.scss */
.dragged-form-item .form-item .input-area .field-label {
  color: var(--Black, #000);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  padding: 0 0 12px 0;
}

/* line 122, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_service-faruk.scss */
.dragged-form-item .form-item label {
  color: #000;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 8px 15px;
}

/* line 133, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_service-faruk.scss */
.dragged-form-item .form-item .input-area .form-group {
  display: flex;
  flex-wrap: wrap;
}

/* line 144, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_service-faruk.scss */
.items-category {
  padding: 20px;
  margin-bottom: 24px;
  border-radius: 12px;
  background: var(--White, #FFF);
  text-align: center;
}

/* line 154, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_service-faruk.scss */
.items-category .title {
  color: #000;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: left;
  padding: 0 0 10px 0;
}

/* line 165, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_service-faruk.scss */
.items-category .form-item {
  padding: 0;
  border-radius: 12px;
  background: none !important;
  border: none;
  text-align: left;
  margin: 0 0 24px 0;
}

/* line 173, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_service-faruk.scss */
.items-category .form-item:last-child {
  margin: 0;
}

/* line 178, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_service-faruk.scss */
.items-category .field-label, .items-category .form-check {
  font-size: 18px;
  font-weight: 600;
  width: 100%;
  display: block;
  padding: 12px 0;
}

/* line 187, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_service-faruk.scss */
.items-category .form-check-label {
  color: #000;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

/* line 197, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_service-faruk.scss */
.items-category .form-check {
  font-size: 18px;
  font-weight: 600;
  width: 25px;
  display: block;
  margin-right: 16px;
}

/* line 205, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_service-faruk.scss */
.items-category .form-row {
  display: flex;
  padding: 6px 0;
  flex-wrap: wrap;
  align-items: center;
}

/* line 210, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_service-faruk.scss */
.items-category .form-row.layer {
  font: normal normal normal 14px / 1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  position: relative;
}

/* line 217, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_service-faruk.scss */
.items-category .form-row.layer::before {
  content: "\f178";
  position: absolute;
  left: -27px;
  top: 5px;
}

/* line 225, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_service-faruk.scss */
.items-category .form-row label {
  font-size: 14px;
  font-weight: normal;
  padding-right: 10px;
  cursor: pointer;
}

/* line 234, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_service-faruk.scss */
.items-category input[type="radio"] {
  width: 16px;
  height: 16px;
  margin-right: 12px;
}

/* line 240, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_service-faruk.scss */
.items-category input[type="checkbox"] {
  width: 16px;
  height: 16px;
  margin-right: 6px;
}

/* line 246, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_service-faruk.scss */
.items-category .form-check-input {
  width: 25px;
  height: 25px;
}

/* line 250, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_service-faruk.scss */
.items-category .view {
  cursor: pointer;
  padding: 2px 4px;
  position: absolute;
  right: 0;
  background: white;
  box-shadow: 0 0 2px 0 #008951;
  border-radius: 5px;
}

/* line 262, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_service-faruk.scss */
.dragged-form-item .form-check-input {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

/* line 268, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_service-faruk.scss */
.dragged-form-item .form-check-inline {
  display: flex;
  align-items: center;
  margin: 0;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 8px 8px 0 0;
}

/* line 276, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_service-faruk.scss */
.dragged-form-item .form-check-inline.active {
  background: #f3f3f3;
}

/* line 280, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_service-faruk.scss */
.dragged-form-item .service-plan {
  margin: 0;
  padding: 12px 0;
}

/* line 285, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_service-faruk.scss */
.dragged-form-item .service-plan-add {
  border: solid 1px;
  padding: 5px;
}

/* line 289, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_service-faruk.scss */
.dragged-form-item .service-plan-add {
  border: solid 1px #E9E9E9;
  padding: 8px;
  border-radius: 6px;
  width: 100%;
}

/* line 296, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_service-faruk.scss */
.category-items {
  background: #D5E7E0;
}

/* line 298, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_service-faruk.scss */
.category-items ol {
  width: 100%;
  margin: 0;
  padding: 0;
}

/* line 304, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_service-faruk.scss */
.category-items ol li {
  padding: 0;
}

/* line 308, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_service-faruk.scss */
.category-items ol ol {
  background: #f2f8f6;
}

/* line 311, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_service-faruk.scss */
.category-items ol ol li {
  padding-left: 8px;
}

/* line 314, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_service-faruk.scss */
.category-items ol ol ol {
  background: #e2eeea;
}

/* line 317, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_service-faruk.scss */
.category-items ol ol ol ol {
  background: #d5e7e0;
}

/* line 435, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-element.scss */
.item-switch {
  width: 100%;
}

/* line 438, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-element.scss */
.item-switch .form-switch {
  display: inline;
  padding-left: 3em;
  margin: 0;
}

/* line 2, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-builder.scss */
.from-group label {
  padding: 8px 0;
  font-weight: bold;
}

/* line 8, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-builder.scss */
.form-builder-area {
  position: relative;
  display: flex;
}

/* line 13, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-builder.scss */
fieldset {
  width: calc(100% - 150px);
  position: relative;
}

/* line 19, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-builder.scss */
fieldset input, fieldset select {
  border: solid 1px #E9E9E9;
  padding: 4px;
}

/* line 25, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-builder.scss */
.subitem-manage legend {
  border: solid 1px #E9E9E9;
  padding: 8px 12px;
  background: white;
  font-size: 16px;
  margin: 0;
}

/* line 34, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-builder.scss */
legend {
  float: none;
  width: auto;
  display: inline;
  border-radius: 8px;
}

/* line 45, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-builder.scss */
.right-control {
  /* position: absolute; */
  right: 0;
  top: 0;
  padding-top: 12px;
}

/* line 52, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-builder.scss */
.right-control ul {
  margin: 0;
  padding: 0;
}

/* line 57, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-builder.scss */
.right-control li {
  border: dotted 1px #ced4da;
  margin: 5px;
  padding: 8px 10px;
  list-style: none;
  width: 150px;
  cursor: pointer;
}

/* line 66, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-builder.scss */
.field-builder {
  /* position: absolute; */
  /* right: 0;
  top: 0; */
  width: 100%;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid var(--stroke, #E9E9E9);
  background: var(--Category-1-BG, #F6F6F6);
  margin-bottom: 8px;
}

/* line 79, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-builder.scss */
.form-item {
  margin: 8px 0;
  padding: 15px;
  position: relative;
  background: #ffffff;
  border: solid 1px #E9E9E9;
  border-radius: 8px;
}

/* line 87, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-builder.scss */
.form-item .editable {
  border-radius: 8px;
}

/* line 90, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-builder.scss */
.form-item .editable .from-group {
  position: relative;
}

/* line 93, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-builder.scss */
.form-item .editable .from-group .add {
  position: absolute;
  right: 0;
  top: 0;
}

/* line 101, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-builder.scss */
.form-item .rank {
  border: 1px solid #aaa;
  border-radius: 50%;
  padding: 0px;
  font-size: 16px;
  text-align: center;
  color: #aaa;
  font-weight: normal;
  position: relative;
}

/* line 111, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-builder.scss */
.form-item .rank i {
  position: absolute;
  top: calc(50% - 7px);
  right: calc(50% - 5px);
}

/* line 116, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-builder.scss */
.form-item .rank:hover {
  color: #fff;
  background: #008951;
}

/* line 121, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-builder.scss */
.form-item .rank-disable {
  border: 1px solid #ddd;
  color: #ddd;
  border-radius: 50%;
  padding: 0px;
  font-size: 18px;
  text-align: center;
  position: relative;
  cursor: auto;
}

/* line 131, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-builder.scss */
.form-item .rank-disable i {
  position: absolute;
  top: calc(50% - 7px);
  right: calc(50% - 5px);
}

/* line 138, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-builder.scss */
.form-item .option {
  position: relative;
  margin-bottom: 10px;
}

/* line 142, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-builder.scss */
.form-item .option .form-control, .form-item .option .form-select {
  width: calc(100% - 50px);
}

/* line 146, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-builder.scss */
.form-item .option .edit-delete-btn {
  position: absolute;
  right: 0;
  top: 0;
}

/* line 153, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-builder.scss */
.form-item .action-area {
  position: absolute;
  right: 15px;
  top: 6px;
  z-index: 9;
  display: flex;
}

/* line 161, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-builder.scss */
.form-item .btn {
  cursor: pointer;
}

/* line 165, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-builder.scss */
.form-item .field-label {
  padding: 6px;
  display: inline-block;
}

/* line 170, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-builder.scss */
.form-item .add-option {
  display: flex;
  width: calc(100% - 50px);
  padding: 8px 16px;
  align-items: center;
  border-radius: 8px;
  background: var(--offer, #D5E7E0);
}

/* line 181, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-builder.scss */
.form-builder {
  position: relative;
}

/* line 190, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-builder.scss */
.form-builder label {
  padding: 12px 6px 8px;
  display: inline-block;
}

/* line 198, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-builder.scss */
.config-title {
  display: inline-flex;
  padding: 0 0 10px 0;
  margin: 0;
  background: var(--White, #FFF);
  font-weight: bold;
}

/* line 213, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-builder.scss */
.drop-area {
  display: flex;
  width: 100%;
  height: 52px;
  padding: 16px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px dashed var(--red_btn, #F00);
  background: #FFF1F1;
  margin: 5px 0;
}

/* line 226, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-builder.scss */
.ungroup-service {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

/* line 231, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-builder.scss */
.ungroup-service label {
  display: block;
  width: 100%;
}

/* line 236, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-builder.scss */
.ungroup-service span {
  margin: 5px;
}

/* line 239, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-builder.scss */
.ungroup-service span:hover {
  background: #ccc;
}

/* line 244, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-builder.scss */
.ungroup-service input {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

/* line 251, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-builder.scss */
.ungroup-checkbox {
  display: flex;
  align-items: center;
}

/* line 255, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-builder.scss */
.ungroup-checkbox input {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

/* line 262, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-builder.scss */
.field-builder .ungroup-checkbox, .subitem-manage .ungroup-checkbox {
  top: -15px;
  position: absolute;
  right: 20px;
}

/* line 268, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-builder.scss */
.form-item .ungroup-checkbox, .subitem-manage .form-group .ungroup-checkbox {
  position: absolute;
  right: 20px;
  top: 63px;
}

/* line 274, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-builder.scss */
.is-ungroup-checkbox {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - 150px);
}

/* line 280, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-builder.scss */
.is-unit-select {
  width: calc(100% - 150px);
}

/* line 282, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-builder.scss */
.is-unit-select .from-group {
  position: absolute;
  right: 0;
  top: 15px;
  width: 150px;
}

/* line 291, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-builder.scss */
.subitem-manage .form-group {
  margin-bottom: 8px;
  padding: 15px;
  position: relative;
  background: #ffffff;
  border: solid 1px #E9E9E9;
  border-radius: 8px;
}

/* line 298, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_form-builder.scss */
.subitem-manage .form-group:last-child {
  margin: 0;
}

/* line 2, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_card.scss */
.card-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px;
}

/* line 13, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_card.scss */
.top-btn-area:empty {
  display: none;
}

/* line 17, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_card.scss */
.top-btn-area .new-configuration-btn {
  min-width: 210px;
  width: calc(25% - 20px);
  padding: 10px 10px 10px 16px;
}

/* line 24, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_card.scss */
.top-btn-area .card {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  margin: 10px;
  position: relative;
  width: calc(25% - 20px);
  min-width: 210px;
  box-shadow: 0 4px 16px 0 rgba(17, 17, 26, 0.05);
  border-radius: 12px;
  border: 1px solid   #E9E9E9;
  background: white;
}

/* line 42, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_card.scss */
.top-btn-area .card .title {
  width: calc(100% - 50px);
}

/* line 45, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_card.scss */
.top-btn-area .card .is-app-in-top {
  position: absolute;
  top: 0;
  right: 0px;
  width: 25px;
  height: 25px;
  text-align: center;
  overflow: hidden;
}

/* line 55, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_card.scss */
.top-btn-area .card .is-app-in-top::before {
  content: '';
  position: absolute;
  top: -10px;
  right: -20px;
  background: #008951;
  rotate: 45deg;
  width: 50px;
  height: 25px;
}

/* line 66, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_card.scss */
.top-btn-area .card .is-app-in-top .fa {
  position: absolute;
  top: 0;
  right: 0px;
  color: white;
  font-size: 12px;
  margin-right: 2px;
  margin-top: 1px;
}

/* line 78, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_card.scss */
.top-btn-area .card hr {
  border-top: dashed 2px #333;
}

/* line 83, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_card.scss */
.top-btn-area .card .status-text {
  padding: 2px;
}

/* line 86, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_card.scss */
.top-btn-area .card .status-text.N {
  color: red;
}

/* line 90, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_card.scss */
.top-btn-area .card .status-text.Y {
  color: green;
}

/* line 94, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_card.scss */
.top-btn-area .card .status-text.D {
  color: orange;
}

/* line 98, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_card.scss */
.top-btn-area .card .status-text.P {
  color: gray;
}

/* line 103, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_card.scss */
.top-btn-area .card a, .top-btn-area .card .link {
  display: flex;
  align-items: center;
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

/* line 117, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_card.scss */
.top-btn-area .card .status-action-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 11px 16px;
  text-align: center;
  cursor: pointer;
  background: #f6f6f6;
  border-radius: 50%;
  visibility: hidden;
}

/* line 129, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_card.scss */
.top-btn-area .card.service {
  background: #D1E7DD;
}

/* line 133, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_card.scss */
.top-btn-area .card.area {
  cursor: pointer;
}

/* line 137, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_card.scss */
.top-btn-area .card .icon {
  display: flex;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid #E9E9E9;
  background: url("../images/icon.jpg") no-repeat;
  margin-right: 10px;
}

/* line 147, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_card.scss */
.top-btn-area .card .qty {
  width: 50px;
  height: 30px;
  position: absolute;
  right: 10px;
  bottom: 8px;
  align-items: center;
  text-align: center;
  display: grid;
  border: solid 1px #ccc;
  z-index: 1;
  border-radius: 4px;
  color: #047857;
}

/* line 177, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_card.scss */
.top-btn-area .card span.qty {
  cursor: pointer;
}

/* line 182, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_card.scss */
.top-btn-area .card h2 {
  font-size: 1.5rem;
  margin: 0;
}

/* line 187, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_card.scss */
.top-btn-area .card:hover .status-action-btn {
  visibility: visible;
}

/* line 193, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_card.scss */
.top-btn-area .fa {
  font-size: 24px;
  margin-right: 10px;
}

/* line 199, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_card.scss */
.card-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

/* line 210, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_card.scss */
.card-button:hover {
  background-color: #0056b3;
}

/* Style for Font Awesome icons */
/* line 217, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_card.scss */
.card-body {
  padding: 30px;
  background: white;
  width: auto;
  border-radius: 12px;
}

/* line 223, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_card.scss */
.card-body .hr {
  border-bottom: solid 1px #efefef;
}

/* line 227, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_card.scss */
.card-body [class^='col-sm'] {
  padding: 12px;
}

/* line 1, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_areas.scss */
.area-add-form {
  position: relative;
}

/* line 3, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_areas.scss */
.area-add-form .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px 12px;
  display: inline-block;
  cursor: pointer;
  color: red;
  font-size: 14px;
}

/* line 14, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_areas.scss */
.area-add-form .form-group {
  display: flex;
  flex-wrap: wrap;
}

/* line 17, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_areas.scss */
.area-add-form .form-group .w-7, .area-add-form .form-group .dropdown {
  width: calc(70% - 20px);
  margin-right: 20px;
}

/* line 20, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_areas.scss */
.area-add-form .form-group .w-7 .btn, .area-add-form .form-group .w-7 .btn-group, .area-add-form .form-group .dropdown .btn, .area-add-form .form-group .dropdown .btn-group {
  width: 100%;
}

/* line 24, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_areas.scss */
.area-add-form .form-group .w-3 {
  width: 30%;
}

/* line 26, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_areas.scss */
.area-add-form .form-group .w-3.btn {
  width: auto;
  margin-left: auto;
  margin-right: 0;
  padding: 14px 20px;
  font-size: 14px;
  border-radius: 12px;
  background: var(--btn-secondary, #E9E9E9);
  color: var(--Black, #000);
  border: none;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

/* line 44, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_areas.scss */
.area-add-form .label {
  width: 100%;
  padding: 0 0 8px 0;
  font-weight: 600;
  font-size: 16px;
}

/* line 50, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_areas.scss */
.area-add-form .radio-btn {
  display: flex;
  border-radius: 8px;
  align-items: center;
  border: 2px solid #E9E9E9;
  background: white;
  margin: 2px;
  cursor: pointer;
  position: relative;
}

/* line 67, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_areas.scss */
.area-add-form .radio-area {
  display: flex;
  flex-wrap: wrap;
  padding: 5px 0;
  margin-top: 5px;
}

/* line 72, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_areas.scss */
.area-add-form .radio-area .green {
  background: #008951;
  color: white;
}

/* line 76, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_areas.scss */
.area-add-form .radio-area .yellow {
  background: #FFFF00;
}

/* line 80, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_areas.scss */
.area-add-form .radio-area .purple {
  background: #800080;
  color: white;
}

/* line 84, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_areas.scss */
.area-add-form .radio-area .combo {
  background: yellow;
}

/* line 88, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_areas.scss */
.area-add-form .radio-area .combo-1 {
  background: #F9F3A7;
}

/* line 92, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_areas.scss */
.area-add-form .radio-area .combo-2 {
  background: #B19CD7;
}

/* line 96, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_areas.scss */
.area-add-form .radio-area .item {
  margin: 12px;
  width: 20px;
  height: 20px;
}

/* line 101, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_areas.scss */
.area-add-form .radio-area .label-name {
  padding-right: 16px;
}

/* line 104, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_areas.scss */
.area-add-form .radio-area .radio-btn {
  margin: 8px 16px 8px 0;
}

/* line 106, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_areas.scss */
.area-add-form .radio-area .radio-btn label {
  display: flex;
  align-items: center;
}

/* line 112, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_areas.scss */
.area-add-form .radio-area .visibility-none {
  position: absolute;
  background: #F6F6F6;
  padding: 2px 0;
  border-radius: 50%;
  right: -8px;
  top: -8px;
  width: 20px;
  height: 20px;
  text-align: center;
  color: #999;
  font-size: 12px;
}

/* line 124, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_areas.scss */
.area-add-form .radio-area .visibility-none:hover {
  background: #D83B01;
  color: white;
}

/* line 138, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_areas.scss */
.custom-select-box .label {
  margin-top: 10px;
}

/* line 142, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_areas.scss */
.offset-droldown {
  width: 100%;
  z-index: 1;
}

/* line 145, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_areas.scss */
.offset-droldown .radio-btn {
  width: 200px;
}

/* line 149, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_areas.scss */
.custom-select {
  position: relative;
  padding: 8px 12px;
  font-size: 14px;
  cursor: pointer;
}

/* line 155, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_areas.scss */
.custom-select i {
  position: absolute;
  right: 12px;
  top: calc(50% - 5px);
  font-size: 20px;
  font: normal normal normal 14px / 1 FontAwesome;
}

/* line 165, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_areas.scss */
.drop-down-modal {
  display: block;
  width: 100%;
  max-height: 300px;
  z-index: 9;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: #FFF;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05), 0px 8px 32px 0px rgba(17, 17, 26, 0.05);
}

/* line 177, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_areas.scss */
.drop-down-modal.scroll-y {
  overflow-y: scroll;
  overflow-x: hidden;
}

/* line 181, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_areas.scss */
.drop-down-modal .search-box {
  position: relative;
  border: solid 1px white;
  margin-bottom: 12px;
}

/* line 187, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_areas.scss */
.drop-down-modal .search-box .form-control {
  padding-left: 35px;
  border-radius: 8px;
  background: #F7F7F7;
}

/* line 192, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_areas.scss */
.drop-down-modal .search-box i {
  position: absolute;
  left: 12px;
  top: 12px;
}

/* line 198, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_areas.scss */
.drop-down-modal.zip-search {
  border-radius: 8px;
  padding: 0;
  border: none;
  box-shadow: none;
}

/* line 204, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_areas.scss */
.drop-down-modal.zip-search .search-box {
  margin: 0;
  background: #FFF;
}

/* line 207, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_areas.scss */
.drop-down-modal.zip-search .search-box .form-control {
  background: white;
}

/* line 212, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_areas.scss */
.drop-down-modal .total-count {
  background: #D5E7E0;
  display: flex;
  padding: 6px;
}

/* line 221, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_areas.scss */
.drop-down-modal .total-count span {
  width: 50%;
  display: inline-block;
}

/* line 224, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_areas.scss */
.drop-down-modal .total-count span:first-child {
  text-align: left;
}

/* line 227, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_areas.scss */
.drop-down-modal .total-count span:last-child {
  text-align: right;
}

/* line 232, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_areas.scss */
.drop-down-modal .item {
  z-index: 9;
}

/* line 236, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_areas.scss */
.drop-down-modal label {
  width: 100%;
  display: flex;
  padding: 8px 12px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #E9E9E9;
  background: white;
}

/* line 244, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_areas.scss */
.drop-down-modal label span {
  width: calc(100% - 30px);
  display: inline-block;
}

/* line 248, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_areas.scss */
.drop-down-modal label input {
  width: 18px;
  height: 18px;
}

/* line 256, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_areas.scss */
.allSelectZipCodes {
  margin: 15px 0;
  display: flex;
  align-items: center;
}

/* line 258, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_areas.scss */
.allSelectZipCodes input {
  width: 20px;
  height: 20px;
  margin: 0 8px;
}

/* line 266, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_areas.scss */
.pause-btn-wrapper {
  width: 100%;
}

/* line 268, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_areas.scss */
.pause-btn-wrapper .btn-submit {
  margin: 5px 10px 5px 0;
}

/* line 272, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_areas.scss */
.pause-active {
  background: #D5E7E0;
  padding-left: 8px !important;
}

/* line 1, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_header-top-content.scss */
.header-top-content {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9;
  max-width: 70%;
  height: 75px;
  align-items: center;
  display: flex;
}

/* line 12, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_header-top-content.scss */
.header-top-content.top-position {
  top: -22px;
}

/* line 16, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_header-top-content.scss */
.date-wrapper {
  position: absolute;
  left: calc(-170px);
  top: 0;
}

/* line 2, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_provider.scss */
.data-table-wrapper .title {
  color: var(--text_color, #000);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

/* line 1, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_custom-card.scss */
.custom-card {
  display: flex;
}

/* line 3, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_custom-card.scss */
.custom-card .card {
  width: 25%;
}

/* line 1, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_pricing-tab.scss */
.pricing-tab {
  display: block;
  padding: 8px;
  align-items: center;
  gap: 36px;
  align-self: stretch;
  border-radius: 6px;
  background: #F6F6F6;
  width: 100%;
}

/* line 10, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_pricing-tab.scss */
.pricing-tab .field-label {
  padding: 6px;
  display: inline-block;
}

/* line 17, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_pricing-tab.scss */
.pricing-tab .form-check-input {
  margin-right: 5px;
  width: 16px;
  height: 16px;
  position: relative;
}

/* line 24, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_pricing-tab.scss */
.pricing-tab .form-check-input:checked {
  background: white;
  border-color: #047857;
  border: solid 2px white;
}

/* line 29, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_pricing-tab.scss */
.pricing-tab .form-check-input:checked::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: solid 3.5px #047857;
  border-radius: 50%;
}

/* line 42, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_pricing-tab.scss */
.pricing-tab .form-check-inline {
  display: inline-block;
  padding: 8px 25px;
  justify-content: center;
  align-items: center;
  margin: 0;
  border-radius: 4px;
  position: relative;
}

/* line 50, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_pricing-tab.scss */
.pricing-tab .form-check-inline.active {
  background: #047857;
  color: white;
}

/* line 68, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_pricing-tab.scss */
.pricing-tab .form-check-inline.areaSize.active {
  background: #047857;
  color: white;
}

/* line 71, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_pricing-tab.scss */
.pricing-tab .form-check-inline.areaSize.active::after {
  content: '';
  position: absolute;
  left: calc(50% - 6px);
  bottom: -5px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #047857;
}

/* line 87, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_pricing-tab.scss */
.pricing-tab .size-type {
  background: #f3f3f3;
  padding: 8px;
  border-radius: 8px;
  margin: 10px 0;
}

/* line 93, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_pricing-tab.scss */
.pricing-tab .size-type .form-check-inline {
  padding: 8px 16px;
}

/* line 95, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_pricing-tab.scss */
.pricing-tab .size-type .form-check-inline.selecetd {
  background: white;
}

/* line 103, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_pricing-tab.scss */
.optional-area-size {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

/* line 111, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_pricing-tab.scss */
.optional-area-size .optional-size-option {
  width: 90px;
}

/* line 114, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_pricing-tab.scss */
.optional-area-size .toogle-icon {
  position: absolute;
  right: 5px;
  top: 8px;
}

/* line 119, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_pricing-tab.scss */
.optional-area-size .remove {
  cursor: pointer;
  width: 25px;
  height: 25px;
  padding: 2px;
  line-height: 20px;
  text-align: center;
  border-radius: 50%;
}

/* line 128, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_pricing-tab.scss */
.optional-area-size .remove:hover {
  color: #008951;
  border: solid 1px #008951;
}

/* line 135, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_pricing-tab.scss */
.measurement-option {
  position: relative;
}

/* line 138, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_pricing-tab.scss */
.measurement-option .measurement-form {
  width: 100px;
  right: 15px;
  position: absolute;
  display: block;
  border-radius: 5px;
  padding: 9px 6px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  text-transform: capitalize;
}

/* line 152, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_pricing-tab.scss */
.measurement-option > .form-control, .measurement-option > .form-select {
  width: calc(100% - 110px);
}

/* line 155, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_pricing-tab.scss */
.measurement-option.sub-item .measurement-form {
  top: 0;
  right: 0;
}

/* line 159, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_pricing-tab.scss */
.measurement-option.active-edit .measurement-form {
  display: none;
}

/* line 1, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_CKEditor.scss */
.ck-editor__editable {
  min-height: 120px;
  max-height: 600px;
}

/* line 6, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_CKEditor.scss */
.ck-content ol, .ck-content ul {
  list-style: unset;
  margin: auto;
  padding: 0 0 0 2rem;
}

/* line 11, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_CKEditor.scss */
.ck-content h2 {
  font-size: 22px;
}

/* line 14, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_CKEditor.scss */
.ck-content h3 {
  font-size: 18px;
}

/* line 1, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_order.scss */
.address-list {
  padding-left: 1rem;
  list-style: circle;
}

/* line 1, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_list-ul-ol.scss */
.list-style {
  list-style: circle;
  margin-left: 15px;
}

/* line 2, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/modal/_loading.scss */
.loader-area {
  margin: 0 auto;
  text-align: center;
}

/* line 5, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/modal/_loading.scss */
.loader-area .message {
  margin-bottom: 30px;
}

/* line 9, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/modal/_loading.scss */
.lds-spinner {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

/* line 16, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/modal/_loading.scss */
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}

/* line 20, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/modal/_loading.scss */
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #fff;
}

/* line 31, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/modal/_loading.scss */
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}

/* line 35, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/modal/_loading.scss */
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}

/* line 39, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/modal/_loading.scss */
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}

/* line 43, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/modal/_loading.scss */
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}

/* line 47, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/modal/_loading.scss */
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}

/* line 51, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/modal/_loading.scss */
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}

/* line 55, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/modal/_loading.scss */
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}

/* line 59, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/modal/_loading.scss */
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}

/* line 63, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/modal/_loading.scss */
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}

/* line 67, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/modal/_loading.scss */
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}

/* line 71, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/modal/_loading.scss */
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}

/* line 75, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/modal/_loading.scss */
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* line 4, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_ErrorComponent.scss */
.error-modal .error-checkmark {
  width: 80px;
  height: 80px;
  margin: 0 auto;
}

/* line 10, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_ErrorComponent.scss */
.error-modal .alert-popup-title {
  color: #f86;
}

/* line 13, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_ErrorComponent.scss */
.error-modal .circle,
.error-modal .circle-border {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

/* line 20, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_ErrorComponent.scss */
.error-modal .circle {
  z-index: 1;
  position: relative;
  background: white;
  transform: scale(1);
  animation: success-anim 700ms ease;
}

/* line 28, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_ErrorComponent.scss */
.error-modal .circle-border {
  z-index: 0;
  position: absolute;
  transform: scale(1.1);
  animation: circle-anim 400ms ease;
  background: #f86;
}

@keyframes success-anim {
  0% {
    transform: scale(0);
  }
  30% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes circle-anim {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1.1);
  }
}

/* line 57, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_ErrorComponent.scss */
.error-modal .error::before,
.error-modal .error::after {
  content: "";
  display: block;
  height: 4px;
  background: #f86;
  position: absolute;
}

/* line 66, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_ErrorComponent.scss */
.error-modal .error::before {
  width: 40px;
  top: 48%;
  left: 16%;
  transform: rotateZ(50deg);
}

/* line 73, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_ErrorComponent.scss */
.error-modal .error::after {
  width: 40px;
  top: 48%;
  left: 16%;
  transform: rotateZ(-50deg);
}

/* line 1, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_DataNotAvailable.scss */
.not-data-section {
  min-height: 100px;
  text-align: center;
  color: #000;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

/* line 3, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_status-bg-color.scss */
.status.Rejected {
  color: #FF0000;
}

/* line 5, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_status-bg-color.scss */
.status.Rejected:before {
  background: #FF0000;
}

/* line 9, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_status-bg-color.scss */
.status.Verified, .status.Completed {
  background: #008951;
  color: white;
}

/* line 12, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_status-bg-color.scss */
.status.Verified:before, .status.Completed:before {
  background: white;
}

/* line 16, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_status-bg-color.scss */
.status.Pending {
  background: #FAA71A;
  color: white;
}

/* line 19, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_status-bg-color.scss */
.status.Pending:before {
  background: white;
}

/* line 23, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_status-bg-color.scss */
.status.Canceled {
  background: #FF0000;
  color: white;
}

/* line 26, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_status-bg-color.scss */
.status.Canceled:before {
  background: white;
}

/* line 30, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_status-bg-color.scss */
.status .pending {
  background-color: #fdbd43;
  /* Orange color for Pending */
}

/* line 34, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_status-bg-color.scss */
.status .approved {
  background-color: #4CAF50;
  /* Green color for Approved */
}

/* line 38, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_status-bg-color.scss */
.status .declined {
  background-color: #f44336;
  /* Red color for Declined */
}

/* line 41, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_status-bg-color.scss */
.status .pending {
  background: #FAA71A;
  padding: 2px 4px;
  font-weight: normal;
  line-height: normal;
  border-radius: 0 5px 0 0;
}

/* line 50, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_status-bg-color.scss */
.status-pending {
  background-color: #f0ad4e;
  color: #fff;
  border: 1px solid #eea236;
}

/* line 56, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_status-bg-color.scss */
.status-approved {
  background-color: #5cb85c;
  color: #fff;
  border: 1px solid #4cae4c;
}

/* line 62, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_status-bg-color.scss */
.status-rejected {
  background-color: #d9534f;
  color: #fff;
  border: 1px solid #d43f3a;
}

/* line 1, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/modal/_SuccessModalComponent.scss */
.alert-popup-container {
  text-align: center;
  padding: 30px;
}

/* line 4, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/modal/_SuccessModalComponent.scss */
.alert-popup-container .btn {
  margin-top: 25px;
}

/* line 9, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/modal/_SuccessModalComponent.scss */
.alert-popup-title {
  font-size: 20px;
  font-weight: bold;
  color: #4CAF50;
  margin: 10px;
  z-index: 2;
  position: relative;
}

/* line 18, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/modal/_SuccessModalComponent.scss */
.alert-popup-message {
  color: #777;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.4;
}

/* line 26, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/modal/_SuccessModalComponent.scss */
.alert-popup-confirm {
  margin-top: 20px;
  margin-bottom: 20px;
}

/* line 31, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/modal/_SuccessModalComponent.scss */
.success-checkmark {
  width: 80px;
  height: 80px;
  margin: 0 auto;
}

/* line 36, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/modal/_SuccessModalComponent.scss */
.success-checkmark .check-icon {
  width: 80px;
  height: 80px;
  position: relative;
  border-radius: 50%;
  box-sizing: content-box;
  border: 4px solid #4CAF50;
}

/* line 44, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/modal/_SuccessModalComponent.scss */
.success-checkmark .check-icon::before {
  top: 3px;
  left: -2px;
  width: 30px;
  transform-origin: 100% 50%;
  border-radius: 100px 0 0 100px;
}

/* line 52, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/modal/_SuccessModalComponent.scss */
.success-checkmark .check-icon::after {
  top: 0;
  left: 30px;
  width: 60px;
  transform-origin: 0 50%;
  border-radius: 0 100px 100px 0;
  animation: rotate-circle 4.25s ease-in;
}

/* line 61, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/modal/_SuccessModalComponent.scss */
.success-checkmark .check-icon::before, .success-checkmark .check-icon::after {
  content: '';
  height: 100px;
  position: absolute;
  background: #FFFFFF;
  transform: rotate(-45deg);
  z-index: 2;
}

/* line 71, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/modal/_SuccessModalComponent.scss */
.success-checkmark .check-icon .icon-line {
  height: 5px;
  background-color: #4CAF50;
  display: block;
  border-radius: 2px;
  position: absolute;
  z-index: 10;
}

/* line 79, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/modal/_SuccessModalComponent.scss */
.success-checkmark .check-icon .icon-line.line-tip {
  top: 46px;
  left: 14px;
  width: 25px;
  transform: rotate(45deg);
  animation: icon-line-tip 0.75s;
}

/* line 87, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/modal/_SuccessModalComponent.scss */
.success-checkmark .check-icon .icon-line.line-long {
  top: 38px;
  right: 8px;
  width: 47px;
  transform: rotate(-45deg);
  animation: icon-line-long 0.75s;
}

/* line 96, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/modal/_SuccessModalComponent.scss */
.success-checkmark .check-icon .icon-circle {
  top: -4px;
  left: -4px;
  z-index: 10;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: absolute;
  box-sizing: content-box;
  border: 4px solid rgba(76, 175, 80, 0.5);
}

/* line 108, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/modal/_SuccessModalComponent.scss */
.success-checkmark .check-icon .icon-fix {
  top: 8px;
  width: 5px;
  left: 26px;
  z-index: 1;
  height: 85px;
  position: absolute;
  transform: rotate(-45deg);
  background-color: #FFFFFF;
}

@keyframes rotate-circle {
  0% {
    transform: rotate(-45deg);
  }
  5% {
    transform: rotate(-45deg);
  }
  12% {
    transform: rotate(-405deg);
  }
  100% {
    transform: rotate(-405deg);
  }
}

@keyframes icon-line-tip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}

@keyframes icon-line-long {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0px;
    top: 35px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}

/* line 1, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/modal/_modal.scss */
.modal-content {
  max-width: 650px;
  width: 90%;
  margin: 0 auto;
  position: relative;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #999;
  border-radius: 6px;
  outline: 0;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
}

/* line 16, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/modal/_modal.scss */
.modal-header {
  min-height: 16.43px;
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
}

/* line 22, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/modal/_modal.scss */
.modal-body {
  position: relative;
  padding: 15px;
}

/* line 27, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/modal/_modal.scss */
.modal-footer {
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
}

/* line 33, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/modal/_modal.scss */
.custom-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  outline: 0;
  display: flex;
  align-items: center;
}

/* line 45, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/modal/_modal.scss */
.custom-modal #cEmailSentBtn {
  color: #fff;
  background-color: #24a48d;
  border-color: #ccc;
  position: relative;
  text-align: center;
  width: 80px;
  cursor: pointer;
}

/* line 55, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/modal/_modal.scss */
.custom-modal .btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid #24a48d;
  border-radius: 4px;
}

/* line 79, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/modal/_modal.scss */
.custom-modal .form-label {
  display: flex;
  flex-wrap: wrap;
}

/* line 84, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/modal/_modal.scss */
.custom-modal .label-name {
  /* width: 200px; */
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 10px;
}

/* line 91, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/modal/_modal.scss */
.custom-modal .label-name::after {
  content: ':';
  position: absolute;
  right: 15px;
}

/* line 97, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/modal/_modal.scss */
.custom-modal .label-name .form-control {
  width: calc(100% - 100px);
}

/* line 103, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/modal/_modal.scss */
.custom-modal .modal-body form > .form-control {
  margin-bottom: 10px;
}

/* line 108, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/modal/_modal.scss */
.custom-dialog {
  background: rgba(51, 51, 51, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  margin: 0;
  max-width: 100%;
}

/* line 117, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/modal/_modal.scss */
.modal-dialog {
  background: rgba(51, 51, 51, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  margin: 0;
  max-width: 100%;
}

/* line 151, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/modal/_modal.scss */
.close-modal {
  cursor: pointer;
  font-size: 30px;
  line-height: 0;
}

/* line 161, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/modal/_modal.scss */
.search-suggestion {
  margin: 0;
  padding: 8px;
}

/* line 166, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/modal/_modal.scss */
.search-suggestion li {
  list-style: none;
}

/* line 170, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/modal/_modal.scss */
.search-suggestion li label {
  padding: 2px 6px;
}

/* line 174, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/modal/_modal.scss */
.selected-items {
  margin: 8px 0;
  border: dashed 1px gray;
}

/* line 179, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/modal/_modal.scss */
.selected-items .item {
  text-transform: capitalize;
  padding: 2px;
}

/* line 184, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/modal/_modal.scss */
.selected-items .item label {
  display: block;
  padding: 5px;
  background-color: #ededed;
  position: relative;
}

/* line 191, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/modal/_modal.scss */
.selected-items .item label:after {
  content: "\e014";
  font-family: 'Glyphicons Halflings';
  position: absolute;
  color: red;
  right: 10px;
  font-size: 10px;
  top: 9px;
}

/* line 201, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/modal/_modal.scss */
.selected-items .item input {
  display: none;
}

/* line 215, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/modal/_modal.scss */
.close-btn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

/* line 225, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/modal/_modal.scss */
.close-btn:hover {
  color: black;
}

/* line 230, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/modal/_modal.scss */
.approve-worker .modal-content {
  width: 450px;
}

/* line 233, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/modal/_modal.scss */
.approve-worker .modal-body {
  text-align: center;
}

/* line 236, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/modal/_modal.scss */
.approve-worker img {
  width: 65px;
  margin: 20px auto;
}

/* line 240, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/modal/_modal.scss */
.approve-worker .btn {
  margin: 30px auto 20px;
}

/* line 245, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/modal/_modal.scss */
.success-modal .modal-content {
  width: 450px;
}

/* line 251, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/modal/_modal.scss */
.sub-item-configuration .close-modal {
  position: absolute;
  right: 6px;
  top: 12px;
}

/* line 256, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/modal/_modal.scss */
.sub-item-configuration .modal-body {
  max-height: 70vh;
  overflow-y: scroll;
}

/* line 261, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/modal/_modal.scss */
.sub-item-configuration .remove-option, .sub-item-configuration .remove, .sub-item-configuration .toogle-icon {
  display: none;
}

/* line 1, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_RemarksComponent.scss */
.disable {
  background: #cccccc;
  color: #fff;
}

/* line 6, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_RemarksComponent.scss */
.remark-section .title {
  font-size: 20px;
}

/* line 9, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_RemarksComponent.scss */
.remark-section .form-control {
  margin: 8px 0;
}

/* line 13, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_RemarksComponent.scss */
.remarks-wrapper {
  width: calc(100% - 20px);
  padding: 10px 0;
  margin: 20px 10px 0 10px;
  position: relative;
}

/* line 19, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_RemarksComponent.scss */
.remarks-wrapper:empty {
  display: none;
}

/* line 24, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_RemarksComponent.scss */
.remarks-wrapper .save-next {
  position: absolute;
  right: 0;
  top: 0;
}

/* line 2, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_top-right-component.scss */
.request-status {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f6f6f6;
  border-radius: 8px;
  width: 100%;
  max-width: 600px;
}

/* line 14, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_top-right-component.scss */
.request-status .request-info {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #555555;
  margin-right: 15px;
}

/* line 22, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_top-right-component.scss */
.request-status .request-info img {
  max-width: 30px;
  max-height: 30px;
  margin: 5px;
}

/* line 29, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_top-right-component.scss */
.request-status .request-info .icon {
  width: 18px;
  height: 18px;
  margin-right: 8px;
}

/* line 35, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_top-right-component.scss */
.request-status .status-badge {
  display: flex;
  align-items: center;
  padding: 6px 12px;
  border-radius: 12px;
  font-size: 14px;
  font-weight: bold;
}

/* line 45, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_top-right-component.scss */
.request-status .status-badge .icon {
  width: 14px;
  height: 14px;
  margin-right: 6px;
}

/* line 51, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_top-right-component.scss */
.request-status .pending {
  background-color: #fdbd43;
  /* Orange color for Pending */
}

/* line 55, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_top-right-component.scss */
.request-status .approved {
  background-color: #4CAF50;
  /* Green color for Approved */
}

/* line 59, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_top-right-component.scss */
.request-status .declined {
  background-color: #f44336;
  /* Red color for Declined */
}

/* line 1, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_price_reqiest.scss */
.service-description {
  height: auto;
  width: 100%;
  position: relative;
  background: #F6F6F6;
  border: solid 20px #F6F6F6;
  border-radius: 8px;
}

/* line 11, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_price_reqiest.scss */
.service-description button {
  display: flex;
  padding: 2px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 30px;
  background: var(--White, #FFF);
  color: #047857;
}

/* line 20, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_price_reqiest.scss */
.service-description .left-content {
  float: left;
}

/* line 25, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_price_reqiest.scss */
.service-description .right-content .description {
  margin-top: 8px;
  margin-bottom: 8px;
}

/* line 30, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_price_reqiest.scss */
.service-description .both {
  clear: both;
}

/* line 33, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_price_reqiest.scss */
.service-description img {
  width: 96px;
  margin-right: 16px;
  border-radius: 5px;
  height: 96px;
  object-fit: cover;
}

/* line 2, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.price-request {
  margin-top: 20px;
}

/* line 4, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.price-request .left-block {
  padding: 20px;
}

/* line 7, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.price-request .rhiht-block {
  padding: 20px;
}

/* line 10, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.price-request .accordion-header .accordion-button {
  width: 100px;
  position: absolute;
  right: 0;
  background: none !important;
}

/* line 29, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.fill-emerald-700 {
  filter: hue-rotate(180deg);
}

/* line 33, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
  margin-bottom: 0 !important;
}

/* .modal-header {
border-bottom: none !important;
} */
/* line 54, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.rounded-8 {
  border-radius: 8px !important;
}

/* line 58, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.rounded-12 {
  border-radius: 12px !important;
}

/* line 62, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.circle {
  border-radius: 50%;
}

/* line 66, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.btn-bg {
  background: #e9e9e9;
}

/* line 70, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.bg {
  background: #f6f6f6;
}

/* line 74, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.bg-war {
  background: #FFF1F1;
}

/* line 78, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.border-red {
  border: 2px solid red;
  color: red;
}

/* line 83, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.approve {
  border: 2px solid transparent;
}

/* Center the profile section */
/* line 88, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.profile-overview {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  width: 100%;
}

/* line 96, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.cover-ic {
  width: 100%;
  /* max-width: 1440px; */
  height: 181px;
  border-radius: 12px;
  background: #7CB4C5;
}

/* line 104, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.provider-profile {
  border-radius: 12px;
  padding: 20px;
  top: 70px;
  text-align: center;
}

/* line 110, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.provider-profile .profile-icon {
  display: flex;
  width: 140px;
  height: 140px;
  background: #f6f6f6;
  padding: 10px;
  border: solid 8px #fff;
  color: #000000;
  font-family: 40px;
}

/* line 123, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.profile-circle {
  border-radius: 50%;
  border: 5px solid white;
  width: 140px;
  height: 140px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background: #008951 url("../images/profile.svg");
  background-size: cover;
  background-repeat: no-repeat;
  /* Center the circle */
}

/* line 137, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.profile-circle img {
  background: #fff;
}

/* line 142, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.profile-icon {
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #008951;
  color: white;
  font-size: 60px;
  text-transform: uppercase;
  font-weight: bold;
}

/* line 159, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.profile-circle img {
  width: 100%;
}

/* line 167, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.name-icon-area img {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  border: solid 1px #D5E7E0;
  margin-right: 10px;
}

/* line 174, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.name-icon {
  border-radius: 50%;
  width: 44px;
  height: 44px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #008951;
  color: #fff;
  text-transform: uppercase;
  font-size: 22px;
}

/* line 189, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.service-icon {
  display: flex;
  flex-wrap: wrap;
  margin: 2px;
  width: 100%;
}

/* line 194, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.service-icon img {
  margin-right: 8px;
  width: 44px;
  border: solid 1px #D5E7E0;
}

/* line 200, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.service-icon .content {
  width: calc(100% - 55px);
  align-self: center;
}

/* line 204, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.service-icon .type {
  color: #008951;
}

/* line 207, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.provider-name {
  margin-top: 10px;
  text-transform: capitalize;
}

/* line 210, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.provider-name h3 {
  color: #000;
  font-size: 26px;
  font-weight: 600;
}

/* line 215, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.provider-name img {
  display: inline-block;
  width: 25px;
}

/* line 225, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.provider-basic-info .item {
  display: flex;
}

/* line 227, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.provider-basic-info .item img {
  width: 20px;
  height: 18.947px;
  flex-shrink: 0;
  margin-right: 8px;
}

/* line 235, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.provider-basic-info .item span {
  color: #535151;
  font-size: 15px;
  font-weight: 500;
}

/* line 241, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.provider-basic-info .location {
  color: #008951;
  font-size: 15px;
  font-weight: 500;
}

/* line 247, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.provider-basic-info .joined {
  color: #535151;
  font-size: 15px;
  font-weight: 500;
}

/* tabs */
/* line 254, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.tab-box {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  row-gap: 40px;
  width: 100%;
}

/* line 267, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.tab-box .tab-btn {
  color: #535151;
  font-size: 16px;
  font-weight: 500;
  border: none;
  padding: 18px;
  cursor: pointer;
}

/* line 276, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.tab-box .tab-btn.active {
  color: #008951;
  border-bottom: 3px solid #008951;
  font-weight: 600;
}

/* line 281, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.tab-box .tab-btn.active.rejected-tag {
  border-color: red;
}

/* line 289, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.tab-section {
  position: relative;
  border-bottom: 2px solid #D9D9D9;
}

/* line 296, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.areas .zip-list .remove {
  background: none;
  border: none;
}

/* line 300, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.areas .zip-list .address {
  display: flex;
}

/* line 302, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.areas .zip-list .address span, .areas .zip-list .address img {
  margin-right: 5px;
}

/* line 308, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.areas h2, .areas h2 button {
  text-transform: capitalize;
}

/* line 314, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.content-box .content h2 {
  margin-bottom: 10px;
}

/* line 319, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.requested-category .list {
  position: relative;
  width: calc(25% - .75rem);
  box-shadow: 0 0 15px 0 #D5E7E0;
}

/* line 323, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.requested-category .list:hover {
  background: #D5E7E0;
  box-shadow: 0 0 15px 0 #E8F5E9;
}

/* line 327, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.requested-category .list .status-icon {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 10px;
}

/* line 333, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.requested-category .list .pending {
  background: #FAA71A;
  padding: 2px 4px;
  font-weight: normal;
  line-height: normal;
  border-radius: 0 5px 0 0;
}

/* line 342, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.accordion-header {
  display: flex;
  margin-bottom: 0;
  background: #CFE2FF;
  border-radius: 10px !important;
  align-items: center;
  position: relative;
}

/* line 350, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.accordion-header .area-checkbox {
  margin-left: 15px;
  height: 18px;
  width: 18px;
}

/* line 356, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.accordion-header .zone-title {
  width: auto;
  line-break: strict;
  margin-left: 8px;
  padding: 6px 10px;
  margin: 10px 0;
}

/* line 363, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.accordion-header .rejected-tag {
  padding: 6px 10px;
  border-radius: 8px;
}

/* line 368, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.rejected-tag {
  background: #FFF1F1 !important;
  color: #D83B01 !important;
  cursor: pointer;
}

/* line 372, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.rejected-tag i {
  color: red;
}

/* line 376, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.accordion-item {
  margin-bottom: 16px;
  border-radius: 10px !important;
}

/* line 382, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.accordion-button {
  border-radius: 15px;
}

/* line 384, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.accordion-button:focus {
  outline: 0 !important;
  box-shadow: none;
  border: none !important;
}

/* line 391, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.accordion-body {
  padding: 0 !important;
}

/* line 393, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.accordion-body table {
  margin: 0;
}

/* line 398, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.general-info,
.business-info,
.requested-category {
  padding: 24px 20px;
  margin-bottom: 30px;
}

/* line 405, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.general-info .about,
.contact,
.address,
h2 {
  color: #000;
  font-size: 16px;
  font-weight: 600;
}

/* line 416, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.business-info .img-icon {
  display: flex;
  align-items: center;
}

/* line 419, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.business-info .img-icon img {
  width: 28px;
}

/* line 423, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.business-info .img-icon span {
  padding: 0 8px;
}

/* line 426, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.business-info .img-icon i {
  font-size: 26px;
}

/* line 431, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.business-info .docs .front, .corporate-info
.business-info .docs .back {
  background: #D5E7E0;
  width: calc(50% - 10px);
  min-height: 100px;
  border: 2px solid #d5e7e0;
  overflow: hidden;
}

/* line 441, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.business-info .docs .front img,
.business-info .docs .back img {
  width: 100%;
  height: 350px;
  object-fit: cover;
  /* or object-fit: contain; depending on your preference */
}

/* line 449, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.corporate-article img, .corporate-tax img {
  max-width: 200px;
}

/* line 454, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.business-info .docs .front,
.business-info .docs .back,
.business-info .docs .header {
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
}

/* line 460, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.address-info span {
  padding-right: 5px;
}

/* line 462, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.address-info span::after {
  content: ', ';
}

/* line 466, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.address-info span:last-child::after {
  content: '';
}

/* line 473, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.general-info span {
  color: #535151;
  font-size: 16px;
  font-weight: 400;
}

/* line 479, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.divider {
  width: 100%;
  border-top: solid 8px  #e6e6e6;
}

/* line 485, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.ssn-header h2 {
  color: #000;
  font-size: 16px;
  font-weight: 600;
}

/* line 491, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.ssn-header h2 span {
  color: #535151;
  font-size: 16px;
  font-weight: 400;
}

/* line 497, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.selfie-info img {
  max-height: 200px;
  max-width: 200px;
}

/* line 503, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.requested-category .list img {
  width: 46px;
  height: 46px;
}

/* line 508, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.providr-status {
  position: relative;
}

/* line 512, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
#statusToggle {
  display: none;
}

/* line 516, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.status-hamburger {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  transition: all .3s ease-in-out;
}

/* line 526, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.status-card {
  display: none;
  position: absolute;
  right: 0;
  background-color: white;
  box-shadow: 0 4px 16px 0 rgba(17, 17, 26, 0.05);
  width: 250px;
  border-radius: 8px;
  z-index: 1;
  animation: slideInRight 0.5s ease-out;
  box-shadow: 0 0 8px 0 #4DAC85;
  border: solid 1px white;
}

/* line 539, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.status-card .status-msg {
  padding: 15px;
  border-bottom: 1px solid #D9D9D9;
}

/* line 543, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.status-card input {
  margin-right: 5px;
}

/* line 546, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.status-card label {
  padding: 8px;
  margin: 4px 15px;
}

/* line 550, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.status-card .status-save-btn {
  background: #047857;
  color: white;
  border-radius: 0 0 8px 8px;
}

/* line 556, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.status-card .remove {
  position: absolute;
  right: 0;
  top: 0;
  padding: 4px;
  width: 20px;
  line-height: 0;
  color: #FF0000;
  align-self: center;
}

@keyframes slideInRight {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* line 581, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
#statusToggle:checked + .status-hamburger + #statusCard {
  display: block;
}

/* line 585, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.status-card label {
  display: flex;
  padding: 4px 0;
  align-items: center;
}

/* line 592, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.status-card button {
  margin-top: 10px;
}

/* line 598, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.status-save-btn {
  border: none;
  color: black;
  padding: 8px 16px;
}

/* line 604, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.img-card {
  height: 229px;
  width: 100%;
}

/* line 609, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.img-card img {
  height: 229px;
  width: 100%;
  overflow: hidden;
  object-fit: cover;
}

/* line 616, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.why h4 {
  color: #000;
  font-size: 16px;
  font-weight: 600;
}

/* line 621, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.strong {
  font-weight: bold;
}

/* line 624, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.info-row {
  padding: 8px 0;
  display: flex;
  flex-wrap: wrap;
  text-transform: capitalize;
}

/* line 629, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.info-row img {
  width: 28px;
}

/* line 632, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.info-row > span {
  width: calc(100% - 30px);
  display: inline-block;
  padding-left: 15px;
}

/* line 639, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
.info-row > span.email {
  text-transform: none;
}

/* line 644, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_provider.scss */
[class^='status-'] {
  font-weight: bold;
}

/* line 1, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_bundle-service-attribute.scss */
.bundle-service {
  width: 100%;
  font-family: Arial, sans-serif;
  margin-bottom: 10px;
}

/* line 7, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_bundle-service-attribute.scss */
.bundle-service .bundle-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 24px 0 20px 0;
}

/* line 14, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_bundle-service-attribute.scss */
.bundle-service .bundle-header h2 {
  font-size: 1.5em;
  margin: 0;
}

/* line 19, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_bundle-service-attribute.scss */
.bundle-service .package-type {
  font-size: 0.9em;
  padding: 4px 8px;
  background: #f5f5f5;
  border-radius: 5px;
  color: #666;
}

/* line 27, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_bundle-service-attribute.scss */
.bundle-service .bundle-card {
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
  padding: 10px;
}

/* line 34, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_bundle-service-attribute.scss */
.bundle-service .bundle-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 5px 5px 0 0;
}

/* line 43, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_bundle-service-attribute.scss */
.bundle-service .bundle-title h3 {
  font-size: 1.2em;
  margin: 0;
}

/* line 48, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_bundle-service-attribute.scss */
.bundle-service .check-mark {
  font-size: 1.5em;
  color: #666;
}

/* line 53, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_bundle-service-attribute.scss */
.bundle-service .options {
  padding: 10px;
}

/* line 57, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_bundle-service-attribute.scss */
.bundle-service .options .options-item {
  margin: 8px 0;
  font-size: 1em;
  color: #333;
  display: block;
}

/* line 62, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_bundle-service-attribute.scss */
.bundle-service .options .options-item img {
  display: inline;
}

/* line 67, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/provider/_bundle-service-attribute.scss */
.bundle-service .options .icon {
  margin-right: 8px;
}

/* line 4, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_data-table.scss */
.reviewTable .ghLWkf div:first-child {
  white-space: normal;
  overflow: visible;
}

/* line 11, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_data-table.scss */
.data-table-wrapper .data-table-icon {
  width: 40px;
}

/* line 1, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/item/_card-nav.scss */
.card-nav {
  margin: 10px;
  position: absolute;
  width: 200px;
  box-shadow: 0 0 8px #cccccc;
  border-radius: 8px;
  z-index: 99999;
  top: 30px;
  right: 10px;
  background: #fff;
}

/* line 12, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/item/_card-nav.scss */
.card-nav .item {
  padding: 15px;
  border-bottom: solid 1px #fff;
  cursor: pointer;
}

/* line 16, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/item/_card-nav.scss */
.card-nav .item:hover {
  background: #eee;
}

/* line 19, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/item/_card-nav.scss */
.card-nav .item a {
  font-size: 14px;
}

/* line 23, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/item/_card-nav.scss */
.card-nav .item strong {
  padding-left: 8px;
}

/* line 27, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/item/_card-nav.scss */
.card-nav .item:last-child {
  border: none;
}

/* line 31, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/item/_card-nav.scss */
.card-nav i {
  font-size: 16px !important;
}

/* line 37, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/item/_card-nav.scss */
.modal-bg {
  width: 100%;
  height: 100%;
  position: fixed;
  margin: 0;
  max-width: 100%;
  z-index: 1;
  top: 0;
  left: 0;
}

/* line 1, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/search-with-filter/_search.scss */
.search-from-data-table {
  display: flex;
  width: calc(100% - 150px);
}

/* line 4, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/search-with-filter/_search.scss */
.search-from-data-table .export-btn {
  width: 130px;
}

/* line 11, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/search-with-filter/_search.scss */
.newNotification-table .search-from-data-table {
  width: calc(100% - 270px);
}

/* line 14, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/search-with-filter/_search.scss */
.newNotification-table .new-configuration-btn {
  padding: 8px;
  margin: 0;
}

/* line 19, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/search-with-filter/_search.scss */
.newNotification-table .new-configuration-btn:before {
  height: 26px;
  width: 26px;
  padding: 4px;
}

/* line 3, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_drag-icon.scss */
.drag-items {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: flex-start;
  gap: 8px;
  border-radius: 12px;
  padding: 24px;
  background: var(--White, #FFF);
  position: -webkit-sticky;
  position: sticky;
  top: 70px;
}

/* line 24, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_drag-icon.scss */
.drag-items .list {
  display: flex;
  width: 100%;
  padding: 12px 20px;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background: #F6F6F6;
  position: relative;
  border: solid 1px #cccccc;
}

/* line 35, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_drag-icon.scss */
.drag-items .list .left-items {
  display: flex;
  gap: 8px;
}

/* line 40, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_drag-icon.scss */
.drag-items .list .label-icon {
  width: 32px;
  height: 20px;
  flex-shrink: 0;
}

/* line 46, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_drag-icon.scss */
.drag-items .list .list-name {
  color: var(--input, #535151);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

/* line 55, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_drag-icon.scss */
.drag-items .list .drag-icon {
  width: 8px;
  height: 16px;
  flex-shrink: 0;
}

/* line 62, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_drag-icon.scss */
.drag-items .list {
  position: relative;
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 20px;
  padding-left: 40px;
}

/* line 64, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_drag-icon.scss */
.drag-items .list:after {
  content: '';
  height: 20px;
  width: 16px;
  position: absolute;
  right: 10px;
  top: calc(50% - 10px);
  background: url(/public/images/drag.svg) no-repeat center;
  background-size: 10px;
}

/* line 79, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_drag-icon.scss */
.drag-items .list:last-child {
  background-image: url(/public/images/instructions.svg);
}

/* line 83, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_drag-icon.scss */
.drag-items .labelField {
  background-image: url(/public/images/labelField.svg);
}

/* line 84, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_drag-icon.scss */
.drag-items .textField {
  background-image: url(/public/images/textField.svg);
}

/* line 85, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_drag-icon.scss */
.drag-items .comboNumberField {
  background-image: url(/public/images/comboNumberField.svg);
}

/* line 86, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_drag-icon.scss */
.drag-items .selectField {
  background-image: url(/public/images/comboNumberField.svg);
}

/* line 87, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_drag-icon.scss */
.drag-items .radioField {
  background-image: url(/public/images/radioField.svg);
}

/* line 88, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_drag-icon.scss */
.drag-items .checkboxField {
  background-image: url(/public/images/checkboxField.svg);
}

/* line 89, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_drag-icon.scss */
.drag-items .textareaField {
  background-image: url(/public/images/textareaField.svg);
}

/* line 1, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/ServiceZone/_service-zone.scss */
.service-zone-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  font-weight: bold;
}

/* line 6, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/ServiceZone/_service-zone.scss */
.service-zone-container .title {
  width: 100%;
  font-size: 20px;
}

/* line 11, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/ServiceZone/_service-zone.scss */
.service-zone-container .service-state {
  border: dashed 1px #008951;
  padding: 8px;
  margin: 5px;
  width: calc(50% - 10px);
}

/* line 18, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/ServiceZone/_service-zone.scss */
.service-zone-container .service-city {
  margin: 5px 0;
  background: #eef7ea;
  padding: 8px;
}

/* line 26, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/ServiceZone/_service-zone.scss */
.service-zone-container sotong {
  font-weight: normal;
}

/* line 1, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/faq/_faq.scss */
.faq-filter-list {
  display: flex;
  width: 100%;
  border-bottom: solid 1px #D9D9D9;
  margin-bottom: 16px;
}

/* line 6, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/faq/_faq.scss */
.faq-filter-list .item {
  padding: 12px 0;
  cursor: pointer;
  border-bottom: solid 2px white;
  margin: 0 12px;
  display: flex;
}

/* line 12, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/faq/_faq.scss */
.faq-filter-list .item img {
  filter: grayscale(100%);
  margin: 0 4px;
}

/* line 17, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/faq/_faq.scss */
.faq-filter-list .item.active, .faq-filter-list .item:hover {
  border-bottom: solid 2px #008951;
  color: #008951;
}

/* line 21, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/faq/_faq.scss */
.faq-filter-list .item.active img, .faq-filter-list .item:hover img {
  filter: grayscale(0%);
}

/* line 3, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_statusCard.scss */
.status-cards {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
}

/* line 13, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_statusCard.scss */
.status-cards .card {
  width: calc(25% - 15px);
  background-color: #f6f6f6;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  position: relative;
  display: flex;
  /* flex-wrap:wrap; */
  /* flex-direction: column column; */
  /* align-items: center; */
}

/* line 26, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_statusCard.scss */
.status-cards .card h3 {
  font-size: 18px;
  margin-bottom: 10px;
  width: 100%;
}

/* line 32, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_statusCard.scss */
.status-cards .card-content {
  /* display: flex; */
  /* align-items: center; */
  gap: 10px;
  width: calc(100% - 50px);
  /* background: #008000; */
}

/* line 40, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_statusCard.scss */
.status-cards .count {
  font-size: 14px;
}

/* line 45, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_statusCard.scss */
.status-cards .card .img {
  width: 40px;
  height: 40px;
  position: absolute;
  /* border: solid 1px #ff0000; */
  right: 20px;
  top: calc(50% - 20px);
}

/* line 3, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/message/_price-request-message.scss */
.admin-declined-message {
  background: white;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

/* line 13, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/message/_price-request-message.scss */
.admin-declined-message .message-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  width: 100%;
}

/* line 23, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/message/_price-request-message.scss */
.admin-declined-message .user-info {
  display: flex;
  align-items: center;
}

/* line 28, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/message/_price-request-message.scss */
.admin-declined-message .profile-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  border: solid 2px #047857;
}

/* line 36, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/message/_price-request-message.scss */
.admin-declined-message .profile-icon .fa {
  border-radius: 50%;
  font-size: 25px;
  border: solid 1px #047857;
}

/* line 45, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/message/_price-request-message.scss */
.admin-declined-message .username {
  font-weight: bold;
  color: #008000;
  margin-right: 10px;
  font-size: 18px;
}

/* line 52, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/message/_price-request-message.scss */
.admin-declined-message .timestamp {
  color: #555555;
  font-size: 14px;
}

/* line 57, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/message/_price-request-message.scss */
.admin-declined-message .edit-icon {
  cursor: pointer;
  position: absolute;
  right: 12px;
  padding: 7px 10px;
  border-radius: 5px;
}

/* line 65, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/message/_price-request-message.scss */
.admin-declined-message .edit-icon:hover {
  background: #F6F6F6;
}

/* line 71, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/message/_price-request-message.scss */
.admin-declined-message .message-body {
  color: #555555;
  font-size: 16px;
  margin-bottom: 10px;
  padding: 0 15px 0 50px;
}

/* line 78, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/message/_price-request-message.scss */
.admin-declined-message .message-body p {
  margin: 0 0 8px 0;
  line-height: 20px;
}

/* line 84, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/message/_price-request-message.scss */
.admin-declined-message .attachment {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
}

/* line 93, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/message/_price-request-message.scss */
.admin-declined-message .attachment-icon {
  margin-right: 8px;
  background: white;
  padding: 8px 12px;
  border-radius: 8px;
}

/* line 102, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/message/_price-request-message.scss */
.admin-declined-message .file-info {
  color: #555555;
  line-height: 20px;
}

/* line 2, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/card/_prodiverCard.scss */
.prodiver-price-request .address-text {
  text-align: left;
}

/* line 6, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/card/_prodiverCard.scss */
.card-section {
  text-align: left;
}

/* line 8, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/card/_prodiverCard.scss */
.card-section .title {
  font-size: 14px;
  text-align: left;
}

/* line 13, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/card/_prodiverCard.scss */
.card-section .sub-title {
  font-size: 14px;
  color: #666666;
}

/* line 20, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/card/_prodiverCard.scss */
.requested-service-container {
  width: 100%;
  border-radius: 8px;
  font-family: Arial, sans-serif;
  font-size: 13px;
}

/* line 27, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/card/_prodiverCard.scss */
.requested-service-container .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* line 33, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/card/_prodiverCard.scss */
.requested-service-container .header h4 {
  margin: 0;
  font-size: 13px;
}

/* line 38, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/card/_prodiverCard.scss */
.requested-service-container .view-all {
  color: #007bff;
  text-decoration: none;
  font-size: 0.9em;
}

/* line 44, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/card/_prodiverCard.scss */
.requested-service-container .sub-header {
  color: #666;
  font-size: 0.85em;
  margin-bottom: 10px;
}

/* line 50, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/card/_prodiverCard.scss */
.requested-service-container .service-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

/* line 56, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/card/_prodiverCard.scss */
.requested-service-container .service-list li {
  border-bottom: 1px solid #F7F7F7;
}

/* line 58, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/card/_prodiverCard.scss */
.requested-service-container .service-list li:last-child {
  border: none;
}

/* line 64, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/card/_prodiverCard.scss */
.requested-service-container .service-list li a:hover {
  background: #F6F6F6;
}

/* line 2, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/order/_order-details-popup.scss */
#orderView .modal-content {
  max-height: 90%;
  overflow-x: auto !important;
}

/* line 6, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/order/_order-details-popup.scss */
#orderView .customized-option {
  margin: 1rem 0;
}

/* line 8, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/order/_order-details-popup.scss */
#orderView .customized-option .title {
  font-weight: bold;
  font-size: 16px;
  background: #047857;
  padding: 8px;
  color: white;
}

/* line 17, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/order/_order-details-popup.scss */
#orderView .customized-option .attribute-list {
  margin: 1rem;
}

/* line 20, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/order/_order-details-popup.scss */
#orderView .customized-option .attribute-list .item {
  padding: 2px;
  border-bottom: 1px solid #E9E9E9;
  display: flex;
  flex-wrap: wrap;
}

/* line 25, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/order/_order-details-popup.scss */
#orderView .customized-option .attribute-list .item span {
  padding: 2px;
}

/* line 29, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/order/_order-details-popup.scss */
#orderView .customized-option .attribute-list img {
  margin-right: 8px;
  display: inline !important;
}

/* line 33, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/order/_order-details-popup.scss */
#orderView .customized-option .attribute-list .right-content {
  width: 40%;
  text-align: right;
}

/* line 37, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/order/_order-details-popup.scss */
#orderView .customized-option .attribute-list .left-content {
  width: 60%;
}

/* line 42, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/order/_order-details-popup.scss */
#orderView .short-description {
  padding: 0 0 10px 0;
}

/* line 45, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/order/_order-details-popup.scss */
#orderView .box {
  width: 100%;
  border-radius: 0.5rem;
  border: 1px solid #047857;
  margin-bottom: 12px;
}

/* line 51, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/order/_order-details-popup.scss */
#orderView .box-title {
  width: 100%;
  background-color: #047857;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 600;
  font-family: 'Inter', sans-serif;
  padding: 0.5rem;
  border-radius: 0.5rem 0.5rem 0 0;
}

/* line 61, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/order/_order-details-popup.scss */
#orderView .box-description {
  margin: 0.75rem;
  color: #525252;
  font-size: 0.875rem;
  font-weight: 500;
  font-family: 'Inter', sans-serif;
  line-height: 25px;
}

/* line 7, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/order/_index.scss */
.order-details .left-block {
  padding: 20px;
}

/* line 10, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/order/_index.scss */
.order-details .service-status {
  color: #047857;
}

/* line 14, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/order/_index.scss */
.order-details .order-table th:last-child, .order-details .order-table td:last-child {
  text-align: right;
}

/* line 18, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/order/_index.scss */
.order-details .btn-order-details {
  color: white;
  cursor: pointer;
}

/* line 21, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/order/_index.scss */
.order-details .btn-order-details:hover {
  background: #4DAC85;
}

/* line 25, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/order/_index.scss */
.order-details .service-plan-title {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #047857;
  padding: 4px 0 6px 0;
}

/* line 22, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_index.scss */
.btn-close-t {
  padding: 10px 35px;
  background-color: #e5e7eb;
  border-radius: 50px;
  font-weight: 600;
  cursor: pointer;
}

/* line 31, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_index.scss */
.search-suggestion {
  margin: 0;
  padding: 8px;
}

/* line 36, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_index.scss */
.search-suggestion li {
  list-style: none;
}

/* line 40, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_index.scss */
.search-suggestion li label {
  padding: 2px 6px;
}

/* line 44, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_index.scss */
.selected-items {
  margin: 8px 0;
  border: dashed 1px gray;
}

/* line 49, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_index.scss */
.selected-items .item {
  text-transform: capitalize;
  padding: 2px;
}

/* line 54, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_index.scss */
.selected-items .item label {
  display: block;
  padding: 5px;
  background-color: #ededed;
  position: relative;
}

/* line 61, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_index.scss */
.selected-items .item label:after {
  content: "\e014";
  font-family: 'Glyphicons Halflings';
  position: absolute;
  color: red;
  right: 10px;
  font-size: 10px;
  top: 9px;
}

/* line 71, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_index.scss */
.selected-items .item input {
  display: none;
}

/* line 76, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_index.scss */
.search-header-area {
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: space-between;
  align-items: center;
}

/* line 83, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_index.scss */
.search-header-area .title {
  padding: 8px;
}

/* line 87, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_index.scss */
.search-header-area .fa {
  font-size: 14px;
}

/* line 90, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_index.scss */
.search-header-area .more-details {
  padding: 8px;
  width: 80px;
}

/* line 94, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_index.scss */
.search-header-area .more-details .text {
  margin-right: 8px;
}

/* line 98, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_index.scss */
.search-header-area .form-control {
  width: 300px;
  margin: 10px 0 10px 10px;
  display: inline-block;
}

/* line 107, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_index.scss */
.search-header-area > div {
  padding: 10px 15px !important;
}

/* line 110, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_index.scss */
.search-header-area .btn {
  width: 150px;
  margin: 10px 0;
}

/* line 115, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_index.scss */
.search-type {
  width: 200px;
  margin: 10px;
  padding: 10px 15px;
}

/* line 119, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_index.scss */
.search-type:last-child {
  margin-right: 0;
}

/* line 125, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_index.scss */
.alert {
  /* padding: 20px;
  background-color: #f44336;
  color: white; */
}

/* line 131, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_index.scss */
.close-btn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

/* line 142, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_index.scss */
.close-btn:hover {
  color: black;
}

/* line 147, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_index.scss */
.accordion-item .item {
  padding: 12px;
  margin: 8px 0;
  cursor: pointer;
}

/* line 154, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_index.scss */
.dashboard-provider .search-from-data-table {
  width: calc(100% - 110px);
}

/* line 157, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_index.scss */
.dashboard-provider .export-btn {
  width: 80px;
  height: 40px;
  align-items: center;
  display: flex;
  text-align: center;
}

/* line 164, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_index.scss */
.dashboard-provider .more-details {
  margin-right: 10px;
}

/* line 166, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/_index.scss */
.dashboard-provider .more-details .text {
  margin-right: 8px;
}

/* line 1, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/pages/_registration.scss */
.registration {
  border: solid red;
}

/* line 1, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_center-container.scss */
.registration .center-container {
  display: flex;
  justify-content: center;
  /* Horizontally center */
  align-items: center;
}

/* line 1, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_login.scss */
.login-form {
  width: 300px;
  background: white;
  padding: 26px;
  box-shadow: 0px 1px 4px gray;
  border-radius: 6px;
}

/* line 7, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_login.scss */
.login-form input {
  font-size: 12px;
}

/* line 10, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/component/_login.scss */
.login-form h3 {
  padding-bottom: 8px;
  text-align: center;
  margin-bottom: 8px;
}

/* line 1, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/dashboard/_providers-list.scss */
.dashboard-page .providers-list {
  background: white;
}

/* line 4, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/dashboard/_providers-list.scss */
.dashboard-page .providers-list .rdt_Pagination {
  display: none;
}

/* line 7, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/dashboard/_providers-list.scss */
.dashboard-page .providers-list .dlUCce {
  margin: 0;
  padding: 0;
}

/* line 1, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/dashboard/_search-forms.scss */
.dashboard-page .provider-search {
  background: #F5F5F5;
  padding: 0 5px;
  display: inline-block;
  margin: 20px;
  border-radius: 10px;
}

/* line 8, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/dashboard/_search-forms.scss */
.dashboard-page .provider-search .search-field {
  background: transparent;
  width: 300px;
  display: inline-block;
  padding: 8px;
  color: #666666;
}

/* line 15, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/dashboard/_search-forms.scss */
.dashboard-page .provider-search .search-field:focus {
  border: none !important;
  outline: green;
}

/* line 22, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/dashboard/_search-forms.scss */
.dashboard-page .provider-search .provider-submit {
  padding: 8px;
  background: transparent;
}

/* line 4, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/dashboard/_style.scss */
.dashboard-page .service-card {
  margin-bottom: 25px;
}

/* line 8, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/dashboard/_style.scss */
.dashboard-page .rounded-8 {
  border-radius: 8px !important;
}

/* line 12, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/dashboard/_style.scss */
.dashboard-page .rounded-12 {
  border-radius: 12px !important;
}

/* line 16, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/dashboard/_style.scss */
.dashboard-page .card.card-flush {
  border: none;
  box-shadow: 0 4px 16px 0 rgba(17, 17, 26, 0.05);
}

/* line 21, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/dashboard/_style.scss */
.dashboard-page .circle-icon {
  background: #D5E7E0;
}

/* line 25, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/dashboard/_style.scss */
.dashboard-page .chart-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1440px;
  margin-bottom: 20px;
  gap: 24px;
}

/* line 34, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/dashboard/_style.scss */
.dashboard-page .bar-chart {
  width: 100%;
}

/* line 38, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/dashboard/_style.scss */
.dashboard-page .curve-chart {
  width: 30%;
}

/* line 42, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/dashboard/_style.scss */
.dashboard-page #orderTableContainer {
  width: 100%;
  max-width: 1440px;
}

/* line 47, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/dashboard/_style.scss */
.dashboard-page .monthly-order .header .title {
  color: var(--Black, #000);
  font-size: 20px;
  font-weight: 600;
}

/* line 53, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/dashboard/_style.scss */
.dashboard-page .curve-chart .header .title {
  color: var(--Black, #000);
  font-size: 20px;
  font-weight: 600;
}

/* line 59, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/dashboard/_style.scss */
.dashboard-page .month-switch .btn {
  color: var(--White, #FFF);
  font-size: 14px;
  font-weight: 500;
}

/* line 65, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/dashboard/_style.scss */
.dashboard-page .legend-indicator {
  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;
  background-color: #bdc5d1;
  border-radius: 50%;
  margin-right: 0.4375rem;
}

/* line 74, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/dashboard/_style.scss */
.dashboard-page .card-flush .info-txt h3 {
  color: var(--Black, #000);
  font-size: 16px;
  font-weight: 700;
}

/* line 80, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/dashboard/_style.scss */
.dashboard-page .card-flush .info-txt .i-cpunter {
  color: var(--Black, #000);
  font-size: 14px;
  font-weight: 500;
}

/* line 86, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/components/dashboard/_style.scss */
.dashboard-page .card-footer div:hover {
  color: #008951;
  transition: all 0.3s ease-out;
}

/* line 28, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/table.scss */
.rdt_TableHead .rdt_TableHeadRow {
  background: #f9fafd !important;
  border-radius: 0 !important;
}

/* line 36, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/table.scss */
.bCocXJ {
  background: #eee !important;
}

/* line 38, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/table.scss */
.bCocXJ:disabled {
  fill: #ccc !important;
}

/* line 43, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/table.scss */
.zip-list button {
  margin: 8px;
  padding: 8px;
}

/* line 44, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/table.scss */
.zip-list {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

/* line 50, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/table.scss */
.zip-list li {
  padding: 5px;
}

/* line 56, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/table.scss */
.zip-list li.selected {
  background: #4daa46;
}

/* line 60, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/table.scss */
.data-table-wrapper {
  background: white;
  padding: 15px;
  border-radius: 12px;
  margin: 10px 0;
}

/* line 67, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/table.scss */
.data-table-wrapper .status {
  border-radius: 4px;
  background: var(--Gray-50, #E9EDF5);
  display: flex;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-left: 20px;
}

/* line 76, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/table.scss */
.data-table-wrapper .status:before {
  content: '';
  border-radius: 50%;
  background: gray;
  display: block;
  width: 8px;
  height: 8px;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
  position: absolute;
  left: 7px;
  top: calc(50% - 4px);
}

/* line 90, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/table.scss */
.data-table-wrapper .status.Rejected {
  color: white;
  background: #FF0000;
}

/* line 93, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/table.scss */
.data-table-wrapper .status.Rejected:before {
  background: white;
}

/* line 97, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/table.scss */
.data-table-wrapper .status.Verified, .data-table-wrapper .status.Completed, .data-table-wrapper .status.Approved {
  background: #047857;
  color: white;
}

/* line 100, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/table.scss */
.data-table-wrapper .status.Verified:before, .data-table-wrapper .status.Completed:before, .data-table-wrapper .status.Approved:before {
  background: white;
}

/* line 104, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/table.scss */
.data-table-wrapper .status.Pending {
  background: #FAA71A;
  color: white;
}

/* line 107, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/table.scss */
.data-table-wrapper .status.Pending:before {
  background: white;
}

/* line 111, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/table.scss */
.data-table-wrapper .status.Canceled {
  background: #FF0000;
  color: white;
}

/* line 114, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/table.scss */
.data-table-wrapper .status.Canceled:before {
  background: white;
}

/* line 119, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/table.scss */
.data-table-wrapper .btn-default {
  border-radius: 6px;
  border: 1px solid   #E9E9E9;
  background: #E9E9E9;
  display: inline-block;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

/* line 128, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/table.scss */
.data-table-wrapper .btn-default:hover {
  background: #149359;
  color: white;
}

/* line 139, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/table.scss */
.reviewTable {
  overflow-y: auto !important;
}

/* line 1, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/shimmer-animation/_shimmer-animation.scss */
.shimmer-animation {
  background: #DDDBDD;
  /* background-image: linear-gradient(to right, #f6f6f6 0%, #e6e6e6 20%, #f6f6f6 40%, #e6e6e6 60%, #f6f6f6 80%, #e6e6e6 100%); */
  background-repeat: no-repeat;
  overflow: hidden;
  position: relative;
}

/* @keyframes shimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
} */
/* line 18, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/shimmer-animation/_shimmer-animation.scss */
.shimmer-animation::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));
  /* background-image: linear-gradient(140deg, #EADEDB 0%, #BC70A4 50%, #BFD641 75%); */
  animation: shimmer 5s infinite;
  content: '';
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

/* line 17, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/style.scss */
img {
  object-fit: contain;
}

/* line 18, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/style.scss */
.rdt_Pagination button {
  background: #999 !important;
}

/* line 22, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/style.scss */
.rdt_Pagination button:disabled {
  background: white !important;
  color: rgba(255, 255, 255, 0.18) !important;
  fill: rgba(255, 255, 255, 0.18) !important;
}

/* line 31, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/style.scss */
.zip-slider-container {
  width: 100%;
  margin-bottom: 2px;
  overflow: hidden;
  position: relative;
}

/* line 39, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/style.scss */
.zip-list-container {
  width: 300%;
  height: 50px;
}

/* line 46, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/style.scss */
.zip-list-slider {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  white-space: nowrap;
  position: absolute;
  left: 0;
  top: 0;
}

/* line 58, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/style.scss */
.zip-list-slider li {
  margin: 5px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

/* line 65, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/style.scss */
.left-arrow, .right-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  font-size: 20px;
  cursor: pointer;
  background-color: #ddd;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
}

/* line 78, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/style.scss */
.left-arrow {
  left: 0;
}

/* line 82, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/style.scss */
.right-arrow {
  right: 0;
}

/* line 86, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/style.scss */
.hidden {
  display: none;
}

/* line 89, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/style.scss */
.primary-color {
  background: #008951;
}

/* line 93, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/style.scss */
.profile-icon {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  text-transform: uppercase;
}

/* line 100, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/style.scss */
.notification-modal {
  height: 100vh;
  position: absolute;
  right: -10px;
  top: -8px;
  overflow-y: scroll;
  box-shadow: 0 0 15px #ccc;
  background: white;
}

/* line 110, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/style.scss */
.notification-counter {
  position: absolute;
  top: -4px;
  left: 12px;
  display: inline-block;
  padding: 0 4px;
  height: 20px;
  border: 2px solid #fff;
  border-radius: 22px;
  text-align: center;
  font-family: "Roboto", "Arial", sans-serif;
  font-size: 12px;
  font-weight: 400;
  background-color: #c00;
  color: #fff;
  line-height: 18px;
}

/* line 129, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/style.scss */
.notification-bell {
  position: relative;
}

/* line 132, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/style.scss */
.ToastContainer {
  position: relative;
}

/* line 149, ../../work2/admin_helpabode/helabode-admin-frontend/src/assets/scss/style.scss */
.reviewTable .multi-line {
  height: auto;
  white-space: normal !important;
}

/*# sourceMappingURL=style.css.map */